import { Button, Row, Col } from "reactstrap";
import Blog from "../../components/dashboard/Blog";
import bg1 from "../../assets/images/bg/image (1).png";
import bg2 from "../../assets/images/bg/image (2).png";
import bg3 from "../../assets/images/bg/image (3).png";
const path = process.env.REACT_APP_ACE_DO;

const BlogData = [
  {
    title: "Data Source Summary",
    description:
      "A Data Source Summary provides a concise overview of data within the source, highlighting key metrics and essential insights.",
    location: `${path}/`,
    logo: bg1,
  },
  {
    title: "Data Validations",
    description:
      "Democratize Rule authoring with UI driven flows that make it easy for business users to author new rules",
    location: `${path}/RawDataRules`,
    logo: bg2,
  },
  {
    title: "Data Reliability",
    description:
      "Data reliability ensures the accuracy, consistency, and dependability of data in a source, enhancing decision-making and operational efficiency.",
    location: `${path}/DataReliability`,
    logo: bg3,
  },
];

const AceDO = () => {
  return (
    <div className="p-4 pt-2">
      <div className="d-flex justify-content-center">
        <h5 className="mb-3 me-5 fw-bold align-content-center">
          AceDO - Data Observability
        </h5>
      </div>
      <div className="d-flex justify-content-center text-secondary">
        <p>
          A data validation tool verifies accuracy, consistency, and integrity
          by checking data against predefined rules.
        </p>
      </div>
      <Row className="mt-5 d-flex justify-content-center me-5">
        {BlogData.map((blg, index) => (
          <Col sm="6" lg="6" xl="3" key={index}>
            <Blog
              title={blg.title}
              text={blg.description}
              loc={blg.location}
              logo={blg.logo}
            />
          </Col>
        ))}
      </Row>
      <div className="row d-flex">
        <div className="d-flex justify-content-center me-5">
          <Button
            style={{
              color: "white",
              background: "#0A0839",
              border: "none",
            }}
            className="me-5"
            onClick={() => {
              window.open(`${path}/`, "AceDo");
            }}
          >
            View all features
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AceDO;
