import React, { useState, useRef, useEffect, useContext } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Copilot.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { Card, CardBody } from "reactstrap";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import Logo from "../../layouts/Logo";
import { DataContext } from "../../DataContext";
const apiUrl = process.env.REACT_APP_API_URL;

const CoPilot = () => {
  const [chatData, setChatData] = useState({
    messages: [],
    userInput: "",
  });
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const { selectedChatId } = useContext(DataContext);
  const { containerAdd } = useContext(DataContext);
  const { newChat, setNewChat } = useContext(DataContext);
  const { container, setContainer } = useContext(DataContext);
  const [displayed, setDisplayed] = useState(false);
  const [typeDuration, setTypeDuration] = useState([]);
  const [containerLength, setContainerLength] = useState(0);
  const typingRef = useRef(null);

  const handleInputChange = (e) => {
    setChatData({
      ...chatData,
      userInput: e.target.value,
    });
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const processAnswer = (answer) => {
    let tableData = [];
    let summary = "";
    let htmlContent = "";
    let document = "";
    let source = [];
    if (Array.isArray(answer)) {
      const firstAnswer = answer[0];
      if (Array.isArray(firstAnswer?.result)) {
        tableData = firstAnswer.result;
        summary = firstAnswer?.summary || "";
        source = firstAnswer?.source || [];
      } else if (firstAnswer?.summary) {
        summary = firstAnswer?.summary || "";
      } else if (firstAnswer?.response) {
        summary = firstAnswer?.response || "";
      } else if (
        Array.isArray(firstAnswer?.source) &&
        firstAnswer?.source.length > 0
      ) {
        source = firstAnswer?.source || [];
      } else {
        summary = firstAnswer?.result || "";
      }
      document = firstAnswer?.document || "";
    } else {
      summary = answer?.summary || "";
    }

    if (document) {
      const cont = document.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
      htmlContent = cont.replace(/\n/g, "<br />");
    }
    setTypeDuration(document);
    return { tableData, summary, htmlContent, document, source };
  };

  const renderLinks = (source) => {
    if (!Array.isArray(source) || source.length === 0) return null;
    console.log(source);
    return (
      <div>
        <p className="mb-0 mt-2 fw-bold">Sources</p>
        <div className="card-container">
          {source.map((ele, index) => {
            let filename =
              ele.split("/").pop() == "prediction_result"
                ? "payment_fraudulent_prediction"
                : ele.split("/").pop();
            const hasExtension = filename.includes(".");
            const iconClass = hasExtension ? "fas fa-link" : "fas fa-database";

            return (
              <div className="card-width" key={index}>
                {hasExtension ? (
                  <a
                    href={ele}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card-link"
                  >
                    <div className="card-content">
                      <i className={iconClass + " card-icon"}></i>
                      <div className="card-title">{filename}</div>
                    </div>
                  </a>
                ) : (
                  <div className="card-content">
                    <i className={iconClass + " card-icon"}></i>
                    <div className="card-title">{filename}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const TypingEffect = ({ content, onTypingComplete, source, table }) => {
    const [displayedText, setDisplayedText] = useState("");
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const typingSpeed = 10;

    useEffect(() => {
      if (!content) return;

      let index = 0;
      const intervalId = setInterval(() => {
        setDisplayedText((prev) => {
          const newText = prev + content[index];
          index++;
          if (index >= content.length) {
            clearInterval(intervalId);
            setIsTypingComplete(true);
            if (onTypingComplete) {
              onTypingComplete();
            }
          }
          return newText;
        });
      }, typingSpeed);

      return () => clearInterval(intervalId);
    }, [content, typingSpeed, onTypingComplete]);

    useEffect(() => {
      if (isTypingComplete && typingRef.current) {
        typingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [isTypingComplete]);

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: displayedText }} />
        {isTypingComplete && table && (
          <div ref={typingRef}>{renderTable(table)}</div>
        )}
        {isTypingComplete && source && (
          <div ref={typingRef}>{renderLinks(source)}</div>
        )}
      </div>
    );
  };

  const sendMessage = async (question) => {
    console.log(question);
    const query = question?.trim() || chatData?.userInput?.trim();
    if (query) {
      const newMessageId = chatData?.messages?.length;
      setLoading(true);
      scrollToBottom();
      const newMessages = [
        ...chatData.messages,
        { id: newMessageId, sender: "user", text: query },
      ];
      setChatData({
        messages: newMessages,
        userInput: "",
      });

      try {
        const response = await axios.post(`${apiUrl}/copilot/chat/`, {
          question: query,
          user_id: userData?.user_id,
          chat_container_id: selectedChatId,
        });
        setNewChat((prevValue) => prevValue + 1);
        if (selectedChatId == null) {
          setContainerLength(1);
        }
        const { tableData, summary, htmlContent, document, source } =
          processAnswer(response?.data?.answer);
        setChatData((prevData) => ({
          ...prevData,
          messages: [
            ...prevData.messages,
            {
              id: newMessageId,
              sender: "ai",
              text: (
                <>
                  <div className="ai-answer-text">
                    {summary && <div>{summary}</div>}
                    {document && (
                      <TypingEffect
                        content={htmlContent}
                        source={source}
                        table={tableData}
                        onTypingComplete={() => {
                          setDisplayed(true);
                        }}
                      />
                    )}
                    {!document && tableData.length > 0 && (
                      <div ref={typingRef}>{renderTable(tableData)}</div>
                    )}
                    {!document && source && <div>{renderLinks(source)}</div>}
                  </div>
                </>
              ),
            },
          ],
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getChatContainerHistory = async () => {
    if (selectedChatId) {
      try {
        setLoading(true);
        setChatData({
          messages: [],
          userInput: "",
        });
        const response = await axios.get(
          `${apiUrl}/copilot/get-chat-container-history/`,
          {
            params: { chat_container_id: selectedChatId },
          }
        );

        const data = response?.data || [];
        const formattedMessages = data.map((item, index) => {
          let content = "";

          if (item?.content) {
            if (Array.isArray(item.content)) {
              const contentItem = item.content[0] || {};

              if (contentItem.result && Array.isArray(contentItem.result)) {
                content = (
                  <>
                    {contentItem.summary && <div>{contentItem.summary}</div>}
                    {contentItem.document && <div>{contentItem.document}</div>}
                    {contentItem.result.length > 0 &&
                      renderTable(contentItem.result)}
                    {contentItem.source &&
                      Array.isArray(contentItem.source) &&
                      contentItem.source.length > 0 && (
                        <div>
                          <p className="mb-0 mt-2 fw-bold">Sources</p>
                          <div className="card-container">
                            {contentItem.source.map((ele, index) => {
                              let filename =
                                ele.split("/").pop() == "prediction_result"
                                  ? "payment_fraudulent_prediction"
                                  : ele.split("/").pop();
                              console.log(filename);
                              const hasExtension = filename.includes(".");
                              const iconClass = hasExtension
                                ? "fas fa-link"
                                : "fas fa-database";

                              return (
                                <div className="card-width" key={index}>
                                  {hasExtension ? (
                                    <a
                                      href={ele}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="card-link"
                                    >
                                      <div className="card-content">
                                        <i
                                          className={iconClass + " card-icon"}
                                        ></i>
                                        <div className="card-title">
                                          {filename}
                                        </div>
                                      </div>
                                    </a>
                                  ) : (
                                    <div className="card-content">
                                      <i
                                        className={iconClass + " card-icon"}
                                      ></i>
                                      <div className="card-title">
                                        {filename}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </>
                );
              } else if (typeof contentItem.response === "string") {
                content = (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contentItem.response
                        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                        .replace(/\n/g, "<br />"),
                    }}
                  />
                );
              } else {
                content = (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contentItem.document
                          ? contentItem.document
                              .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                              .replace(/\n/g, "<br />")
                          : "No document content available.",
                      }}
                    />
                    {contentItem.source &&
                      Array.isArray(contentItem.source) &&
                      contentItem.source.length > 0 && (
                        <div>
                          <p className="mb-0 mt-2 fw-bold">Sources</p>
                          <div className="card-container">
                            {contentItem.source.map((ele, index) => {
                              let filename =
                                ele.split("/").pop() == "prediction_result"
                                  ? "payment_fraudulent_prediction"
                                  : ele.split("/").pop();
                              const hasExtension = filename.includes(".");
                              const iconClass = hasExtension
                                ? "fas fa-link"
                                : "fas fa-database";

                              return (
                                <div className="card-width" key={index}>
                                  {hasExtension ? (
                                    <a
                                      href={ele}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="card-link"
                                    >
                                      <div className="card-content">
                                        <i
                                          className={iconClass + " card-icon"}
                                        ></i>
                                        <div className="card-title">
                                          {filename}
                                        </div>
                                      </div>
                                    </a>
                                  ) : (
                                    <div className="card-content">
                                      <i
                                        className={iconClass + " card-icon"}
                                      ></i>
                                      <div className="card-title">
                                        {filename}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </>
                );
              }
            } else if (typeof item.content === "string") {
              content = (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.content
                      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                      .replace(/\n/g, "<br />"),
                  }}
                />
              );
            } else if (Array.isArray(item.content)) {
              const contentItem = item.content[0] || {};
              if (typeof contentItem.response === "string")
                content = (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contentItem.response
                        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                        .replace(/\n/g, "<br />"),
                    }}
                  />
                );
            }
          }

          return {
            id: index,
            sender: item.type === "human" ? "user" : "ai",
            text: content,
          };
        });

        setChatData({
          messages: formattedMessages,
          userInput: "",
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setChatData({
        messages: [],
        userInput: "",
      });
    }
  };

  useEffect(() => {
    if (containerLength != 1 || selectedChatId == null) {
      getChatContainerHistory();
    }
  }, [selectedChatId, containerAdd]);

  useEffect(() => {
    if (container == 0) {
      getChatContainerHistory();
      setContainer(1);
    }
  }, [container]);

  const handleCardClick = (question) => {
    sendMessage(question);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const renderTable = (data) => {
    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    return (
      <div ref={typingRef} style={{ maxWidth: "100%", overflowX: "auto" }}>
        <table className="mt-1 table table-striped">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData.messages]);

  return (
    <div className="chat-container">
      {chatData?.messages?.length > 0 ? (
        <div className="chat-box" ref={chatContainerRef}>
          {chatData.messages.map((msg, index) => (
            <div key={index} className="row d-flex">
              <div
                className={msg.sender === "user" ? "d-none" : "col-1 pt-3 me-5"}
              >
                <Logo />
              </div>
              <div
                className={`message ${msg.sender} col-11`}
                style={msg.sender === "user" ? {} : { marginLeft: "-2.7rem" }}
              >
                {msg.text}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-center mt-5 me-5">
            <div className="me-2">
              <Logo />
            </div>
            <div>
              <h2 className="fw-bold text-secondary me-5">BleuBird</h2>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center pt-5">
            <div>
              <h6 className="fw-bold text-secondary">
                Streamline tasks and boost efficiency with intelligent chat that
                simplifies your workflow effortlessly.
              </h6>
            </div>
          </div>
          <div
            className="justify-content-center d-flex align-items-center mb-5"
            style={{ height: "80vh" }}
          >
            <div className="d-flex">
              <div className="me-3">
                <Card
                  style={{ width: "220px", cursor: "pointer" }}
                  onClick={() =>
                    handleCardClick(
                      "Show me the fraud transactions identified in the past week"
                    )
                  }
                >
                  <CardBody className="p-4" style={{ height: "150px" }}>
                    <i
                      className="fa-solid fa-dollar-sign"
                      style={{ color: "skyblue", fontSize: "22px" }}
                    ></i>
                    <p
                      style={{ fontSize: "14px" }}
                      className="mt-3 text-center"
                    >
                      Show me the fraud transactions identified in the past week
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div>
                <Card
                  style={{ width: "220px", cursor: "pointer" }}
                  onClick={() =>
                    handleCardClick(
                      "How many fraud transactions occurred today?"
                    )
                  }
                >
                  <CardBody className="p-4" style={{ height: "150px" }}>
                    <i
                      className="fa-solid fa-building-columns"
                      style={{ color: "orange", fontSize: "22px" }}
                    ></i>
                    <p
                      style={{ fontSize: "14px" }}
                      className="mt-3 text-center"
                    >
                      How many fraud transactions occurred today?
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="ms-3">
                <Card
                  style={{ width: "220px", cursor: "pointer" }}
                  onClick={() =>
                    handleCardClick(
                      "What should be done immediately after a fraud transaction is detected?"
                    )
                  }
                >
                  <CardBody className="p-4" style={{ height: "150px" }}>
                    <i
                      className="fas fa-exclamation-triangle"
                      style={{ color: "red", fontSize: "22px" }}
                    ></i>
                    <p
                      style={{ fontSize: "14px" }}
                      className="mt-3 text-center"
                    >
                      What should be done immediately after a fraud transaction
                      is detected?
                    </p>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </>
      )}

      {loading ? (
        <div className="d-flex">
          <div className={"col-1"}>
            <Logo />
          </div>
          <div className="pt-3">
            <div className="dot-loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="parent-container">
        <div className="input-container d-flex mt-2">
          {loading ? (
            <input
              type="text"
              value="Generating response..."
              disabled
              className="search-input bg-white"
              style={{ border: "none", outline: "none" }}
            />
          ) : (
            <input
              type="text"
              value={chatData?.userInput}
              onChange={handleInputChange}
              placeholder="Ask Anything..."
              onKeyDown={handleKeyDown}
              className="search-input"
              style={{ border: "none", outline: "none" }}
            />
          )}
          <button
            className="search-button me-3"
            onClick={() => sendMessage()}
            disabled={loading}
          >
            <SendIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoPilot;
