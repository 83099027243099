import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Box, Tabs, Tab } from "@mui/material";
import Modal from "@mui/material/Modal";
import Loader from "../../layouts/loader/Loader";
import { Card, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import "./dataTable.css";
import { toast } from "react-toastify";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

function Config() {
  const [Etlloader, setEtlLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [configData, setConfigData] = useState([]);
  const [tables, setTables] = useState([]);
  const [repos, setRepos] = useState([]);
  const [copySource, setCopySource] = useState(false);
  const [newConfigError, setNewConfigError] = useState("");
  const [newSourceConfig, setNewSourceConfig] = useState({});
  const [updateSource, setUpdateSource] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState();
  const [configure, setConfigure] = useState(false);
  const [editConfigError, setEditConfigError] = useState("");

  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    width: "40%",
  };

  const getConfigData = async () => {
    try {
      // if (configData?.length > 0) {
      //   setEtlLoader(false);
      // } else {
      //   setEtlLoader(true);
      // }
      const response = await axios.get(`${apiUrl}/copilot/copilot_sources/`);
      let data = response?.data;
      setConfigData(data);
      // setEtlLoader(false);
    } catch (error) {
      setEtlLoader(false);
      console.error("Failed to fetch data:", error);
    }
  };
  const getTables = async () => {
    try {
      const response = await axios.get(`${apiUrl}/copilot/cassandra/tables/`);
      let data = response?.data?.tables;
      console.log(data);
      setTables(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  const getRepos = async () => {
    try {
      const response = await axios.get(`${apiUrl}/copilot/repodetails/`);
      let data = response?.data?.repo;
      console.log(data);
      setRepos(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleCreateConfigure = async () => {
    if (
      newSourceConfig?.source &&
      newSourceConfig?.source_description &&
      newSourceConfig?.type
    ) {
      try {
        setConfigure(true);
        const response = await axios.post(
          `${apiUrl}/copilot/copilot_sources/`,
          newSourceConfig
        );
        setCopySource(false);
        setConfigure(false);
        toast.success("Configuration added successfully...");
        getConfigData();
      } catch (error) {
        setConfigure(false);
        setCopySource(false);
        console.error("Failed to fetch data:", error);
        toast.error(error?.response?.data?.error);
      }
    } else {
      setNewConfigError("Please fill all the required fields");
    }
  };

  const UpdateConfig = async () => {
    if (newSourceConfig?.source && newSourceConfig?.source_description) {
      try {
        setConfigure(true);
        const response = await axios.put(
          `${apiUrl}/copilot/copilot_sources/${newSourceConfig?.source}/`,
          { source_description: newSourceConfig?.source_description }
        );
        setConfigure(false);
        setUpdateSource(false);
        toast.success("Configuration edited successfully...");
        getConfigData();
      } catch (error) {
        setConfigure(false);
        console.error("Failed to fetch data:", error);
        toast.error(error?.response?.data?.error);
      }
    } else {
      setEditConfigError("Please fill all the required fields");
    }
  };

  useEffect(() => {
    getConfigData();
    getTables();
    getRepos();
  }, []);

  const handleModalClosed = () => {
    setDeleteModal(false);
  };

  const handleDeleteSource = (ele) => {
    setSourceToDelete(ele);
    setDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${apiUrl}/copilot/copilot_sources/${sourceToDelete?.source}/`
      );
      setDeleteModal(false);
      getConfigData();
      toast.success("Row deleted successfully");
    } catch (error) {
      setDeleteModal(false);
      console.error("Error communicating with the API:", error.message);
      toast.error(error?.response?.data?.error);
    }
  };

  const columns = [
    {
      name: "TYPE",
      selector: (row) => row.type,
      style: { fontSize: "16px" },
      sortable: true,
      width: "15% !important",
    },
    {
      name: "SOURCE",
      selector: (row) => row.source,
      style: { fontSize: "16px" },
      sortable: true,
      width: "20% !important",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => (
        <Tooltip title={row.source_description || ""} placement="top">
          <span>
            {row.source_description?.substring(0, 60) +
              (row.source_description?.length > 60 ? "..." : "")}
          </span>
        </Tooltip>
      ),
      style: { fontSize: "16px" },
      sortable: true,
      width: "45% !important",
    },
    {
      name: "ACTION",
      width: "15% !important",
      cell: (row) => (
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <Tooltip title="Edit" placement="top">
            <i
              className="fa-solid fa-pen text-secondary"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                setUpdateSource(true);
                setEditConfigError("");
                setNewSourceConfig(row);
              }}
            ></i>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <i
              className="fa-solid fa-trash"
              style={{ cursor: "pointer", color: "#976565" }}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteSource(row);
              }}
            ></i>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <div>
      {configure ? (
        <div
          className="position-fixed d-flex justify-content-center align-items-center"
          style={{
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="backdrop"></div>
          <div className="spinner-container">
            <Loader />
          </div>
        </div>
      ) : null}
      {Etlloader ? (
        <div
          className="position-fixed d-flex justify-content-center align-items-center"
          style={{
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="pt-4">
          <Card className="p-4 pb-0 mb-0">
            <>
              {" "}
              <div className="d-flex justify-content-between w-100 mb-3">
                <h5>CoPilot Configuration</h5>
                <Button
                  style={{
                    color: "white",
                    background: "#0A0839",
                    border: "none",
                    height: "36px",
                  }}
                  className=""
                  onClick={() => {
                    setNewSourceConfig({});
                    setCopySource(true);
                    setNewConfigError("");
                  }}
                >
                  Create
                </Button>
              </div>
              <DataTable
                columns={columns}
                data={configData}
                pagination
                style={{ tableLayout: "fixed" }}
                responsive={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 25, 50]}
                // highlightOnHover
                pointerOnHover
                customStyles={{
                  table: {
                    style: {
                      backgroundColor: "#fff",
                      color: "#ffffff",
                      border: "1px solid #ECECEC",
                      zIndex: "100",
                      fontSize: "16px",
                      width: "100%",
                    },
                  },
                  rows: {
                    style: {
                      backgroundColor: "#fff",
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#ECECEC",
                      },
                      minHeight: "39px !important",
                    },
                  },
                  headRow: {
                    style: {
                      fontSize: "16px",
                    },
                  },
                  cells: {
                    style: {
                      borderColor: "#ffffff",
                      fontSize: "16px",
                    },
                  },
                }}
              />
            </>
          </Card>
        </div>
      )}

      <Modal
        open={copySource}
        onClose={() => setCopySource(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "40%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Create CoPilot Configure</h5>
          </div>
          <div className="row mt-3 ms-2 me-2">
            <div className="col-12 d-flex mb-3">
              <div className="col-4">
                <label>
                  Type <span className="text-danger ms-1">*</span>
                </label>
              </div>

              <select
                className="form-control"
                name="type"
                value={newSourceConfig?.type}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setNewSourceConfig((prevSource) => ({
                    ...prevSource,
                    [name]: value,
                  }));
                  setNewConfigError("");
                }}
              >
                <option value="">Select a type</option>

                <option value="repo">repo</option>
                <option value="table">table</option>
              </select>
            </div>
            {newSourceConfig?.type == "table" && (
              <>
                <div className="col-12 d-flex mb-3">
                  <div className="col-4">
                    <label>
                      Table <span className="text-danger ms-1">*</span>
                    </label>
                  </div>

                  <select
                    className="form-control"
                    name="source"
                    value={newSourceConfig?.source}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setNewSourceConfig((prevSource) => ({
                        ...prevSource,
                        [name]: value,
                      }));
                      setNewConfigError("");
                    }}
                  >
                    <option value="">Select a table</option>
                    {tables.map((table, index) => (
                      <option key={index} value={table}>
                        {table}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-4">
                    <label>
                      Source Description{" "}
                      <span className="text-danger ms-1">*</span>{" "}
                    </label>
                  </div>
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control"
                    name="source_description"
                    value={newSourceConfig?.source_description}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setNewSourceConfig((prevSource) => ({
                        ...prevSource,
                        [name]: value,
                      }));
                      setNewConfigError("");
                    }}
                  />
                </div>
              </>
            )}
            {newSourceConfig?.type == "repo" && (
              <>
                <div className="col-12 d-flex mb-3">
                  <div className="col-4">
                    <label>
                      Repo <span className="text-danger ms-1">*</span>
                    </label>
                  </div>

                  <select
                    className="form-control"
                    name="source"
                    value={newSourceConfig?.repo_id || ""}
                    onChange={(event) => {
                      const selectedRepoId = Number(event.target.value);
                      const selectedRepo = repos.find(
                        (repo) => repo.repo_id === selectedRepoId
                      );
                      setNewSourceConfig((prevSource) => ({
                        ...prevSource,
                        source: selectedRepo?.repo_name,
                        repo_id: selectedRepo?.repo_id,
                        source_description: selectedRepo?.repo_description,
                      }));
                      setNewConfigError("");
                    }}
                  >
                    <option value="">Select a repo</option>
                    {repos.map((repo, index) => (
                      <option key={index} value={repo.repo_id}>
                        {repo.repo_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-4">
                    <label>
                      Repo Description{" "}
                      <span className="text-danger ms-1">*</span>{" "}
                    </label>
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    rows={3}
                    name="source_description"
                    value={newSourceConfig?.source_description}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setNewSourceConfig((prevSource) => ({
                        ...prevSource,
                        [name]: value,
                      }));
                      setNewConfigError("");
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <div className=" d-flex justify-content-end align-items-center pb-3 mt-3">
            <div>
              {newConfigError ? (
                <>
                  <p className="text-danger me-3 mt-2 pt-2">{newConfigError}</p>
                </>
              ) : null}
            </div>
            <button
              className="form-control me-4 ps-4 pe-4"
              style={{
                backgroundColor: "#0A0839",
                color: "white",
                width: "fit-content",
              }}
              onClick={() => handleCreateConfigure()}
            >
              Create
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={updateSource}
        onClose={() => setUpdateSource(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "40%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Edit CoPilot Configure</h5>
          </div>
          <div className="row mt-3 ms-2 me-2">
            <div className="col-12 d-flex mb-3">
              <div className="col-4">
                <label>
                  Type <span className="text-danger ms-1">*</span>
                </label>
              </div>

              <input
                type="text"
                className="form-control"
                name="source"
                value={newSourceConfig?.type}
                disabled="true"
              />
            </div>
            <div className="col-12 d-flex mb-3">
              <div className="col-4">
                <label>
                  Table <span className="text-danger ms-1">*</span>
                </label>
              </div>

              <input
                type="text"
                className="form-control"
                name="source"
                value={newSourceConfig?.source}
                disabled="true"
              />
            </div>
            <div className="col-12 d-flex">
              <div className="col-4">
                <label>
                  Source Description <span className="text-danger ms-1">*</span>{" "}
                </label>
              </div>
              <textarea
                type="text"
                className="form-control"
                name="source_description"
                rows={3}
                value={newSourceConfig?.source_description}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setNewSourceConfig((prevSource) => ({
                    ...prevSource,
                    [name]: value,
                  }));
                  setEditConfigError("");
                }}
              />
            </div>
          </div>

          <div className=" d-flex justify-content-end align-items-center pb-3 mt-3">
            <div>
              {editConfigError ? (
                <>
                  <p className="text-danger me-3 mt-2 pt-2">
                    {editConfigError}
                  </p>
                </>
              ) : null}
            </div>
            <button
              className="form-control me-4 ps-4 pe-4"
              style={{
                backgroundColor: "#0A0839",
                color: "white",
                width: "fit-content",
              }}
              onClick={() => UpdateConfig()}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "30%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Delete Configure</h5>
          </div>
          <div className=" d-flex justify-content-center align-items-center p-3">
            <p>Are you sure you want to delete?</p>
          </div>
          <div className=" d-flex justify-content-center align-items-center pb-3">
            <button
              className=" ms-5 me-4 form-control "
              onClick={() => handleModalClosed()}
            >
              No
            </button>
            <button
              className="form-control me-5 "
              onClick={() => handleConfirmDelete()}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Config;
