import React, { useState } from "react";
import { Cron } from "react-js-cron";
import "./main.css";

function CronTab({ cronTabValue, setCronTabValue }) {
  const [allowedDropdowns, setAllowedDropdowns] = useState([
    "period",
    "months",
    "month-days",
    "week-days",
    "hours",
  ]);

  const handleCronChange = (newValue, metadata) => {
    if (metadata.selectedPeriod === "month") {
      setAllowedDropdowns(["period", "months", "month-days", "hours"]);
      const updatedCronValue = newValue.replace(/(\S+)\s*$/, "*");
      setCronTabValue(updatedCronValue);
    } else {
      setAllowedDropdowns([
        "period",
        "months",
        "month-days",
        "week-days",
        "hours",
      ]);
      setCronTabValue(newValue);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <Cron
        value={cronTabValue}
        setValue={handleCronChange}
        mode="single"
        isClearable={false}
        allowedDropdowns={allowedDropdowns}
        allowedPeriods={["month", "week", "day"]}
        clearButton={false}
        periodicityOnDoubleClick={false}
      />
      <span style={{ marginLeft: "5px" }}>EST</span>
    </div>
  );
}

export default CronTab;
