import React from "react";
import { DataProvider } from "./DataContext";
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { ToastContainer } from "react-toastify";

const App = () => {
  const routing = useRoutes(Themeroutes);

  return (
    <DataProvider>
      <ToastContainer position="top-center"/>
      {routing}
    </DataProvider>
  );
};

export default App;
