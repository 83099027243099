import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Card, Button } from "reactstrap";
import CronTab from "./CronTab.jsx";
import "./main.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../layouts/loader/Loader.js";
const apiUrl = process.env.REACT_APP_API_URL;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([0]);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const currentDate = new Date().toISOString();
  const initColumn = {
    data_name: "",
    entity_name: "",
    source_type: "",
    source_details: {
      sourceMetadata: {
        tableOrder: 0,
        groupId: "",
        hasControlFile: false,
        source: "s3",
        cloud: {
          s3: {
            path: {
              s3Bucket: "",
              folder: "",
            },
            schemaType: "",
            dataFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
              filename: "",
            },
            controlFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
            },
            connectionDetails: {
              s3URI: "",
              authType: "",
              region: "",
              secretName: "",
            },
            fileTypeForMissingFileValidation: "",
          },
          azure: {
            path: {
              blobContainer: "",
              blobPath: "",
            },
            schemaType: "",
            dataFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
            },
            controlFile: {
              affixType: "",
              fileNameAffix: "",
              archivedFileNameAffix: "",
              fileType: "",
              delimiter: "",
              header: false,
              excludeFileNameAffix: [],
            },
            connectionDetails: {
              storageAccount: "",
              blobURI: "",
              authType: "",
              secretName: "",
            },
            fileTypeForMissingFileValidation: "",
          },
        },
        streaming: {
          kafka: {
            bootstrapServers: "",
            partitions: null,
            replicationFactor: null,
            sourceTopic: "",
          },
        },
        database: {
          mongoDb: {},
          postgres: {
            TableDetails: {
              tableName: "",
              excludeTableName: [],
            },
            controlTableDetails: {
              tableName: "",
              excludeTableAffix: [],
            },
            databaseInfo: {
              databaseName: "",
              schemaName: "",
            },
          },
          oracleDb: {},
        },
      },
    },
    source_schema: {
      schema: {
        type: "struct",
        fields: [{ name: "", type: "text", nullable: true }],
      },
      constraints: {
        primarykeys: [],
      },
      extractDate: {
        isAvailable: false,
        columnName: null,
      },
    },
    target_type: "cassandra",
    target_details: {
      targetSystems: {
        snowflake: {
          snowFlakeTableName: "",
          tableType: "",
        },
        cassandra: {
          cassandraTableName: "",
          keyspace: "",
          tableType: "",
        },
      },
    },
    target_schema: {
      schema: {
        type: "struct",
        fields: [],
      },
      constraints: {
        primarykeys: [],
      },
      extractDate: {
        isAvailable: false,
        columnName: null,
      },
    },
    frequency: "DAILY",
    schedule: "* * * * *",
    created_at: currentDate,
    modified_at: currentDate,
  };
  const [cronValue, setCronValue] = useState("");
  const [newSource, setNewSource] = useState(initColumn);
  const [metaDataRequired, setMetaDataRequired] = useState("");
  const [sourceDetailsRequired, setSourceDetailsRequired] = useState("");
  const [schemaRequired, setSchemaRequired] = useState("");
  const isFirstRender = useRef(true);
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [sourceLoader, setSourceLoader] = useState(false);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleNextTab = () => {
    const nextValue = value + 1;
    if (value == 0) {
      if (
        newSource?.data_name &&
        newSource?.entity_name &&
        newSource?.source_type
      ) {
        setMetaDataRequired("");
        setValue(nextValue);
      } else {
        setMetaDataRequired("Please fill all required fields.");
      }
    }
    if (value == 1) {
      let isValid = false;
      let errorMessage = "Please fill all required fields.";

      if (newSource?.source_type == "database") {
        const databaseInfo =
          newSource?.source_details?.sourceMetadata?.database?.postgres
            ?.databaseInfo;
        if (databaseInfo?.databaseName && databaseInfo?.schemaName) {
          isValid = true;
        }
      } else if (newSource?.source_type == "streaming") {
        const kafka =
          newSource?.source_details?.sourceMetadata?.streaming?.kafka;
        if (kafka?.bootstrapServers && kafka?.sourceTopic) {
          isValid = true;
        }
      } else {
        const source = newSource?.source_details?.sourceMetadata?.source;
        const s3 = newSource?.source_details?.sourceMetadata?.cloud?.s3?.path;
        const dataFile =
          newSource?.source_details?.sourceMetadata?.cloud?.s3?.dataFile;
        if (source == "azure") {
          const azurePath =
            newSource?.source_details?.sourceMetadata?.cloud?.azure?.path;
          const azureData =
            newSource?.source_details?.sourceMetadata?.cloud?.azure?.dataFile;
          if (azurePath?.blobContainer && azurePath?.blobPath) {
            isValid = true;
          }
          if (
            azureData?.header &&
            azureData?.fileType &&
            azureData?.delimiter &&
            azureData?.filename
          ) {
            isValid = true;
          }
          const metadata = newSource?.source_details?.sourceMetadata;
          if (
            metadata?.tableOrder &&
            metadata?.hasControlFile &&
            newSource?.source_details?.sourceMetadata?.cloud?.azure?.schemaType
          ) {
            isValid = true;
          }
        } else if (source == "s3") {
          if (s3?.s3Bucket && s3?.folder) {
            isValid = true;
          }
          console.log(s3);
          console.log(dataFile);
          if (
            dataFile?.header &&
            dataFile?.fileType &&
            dataFile?.delimiter &&
            dataFile?.filename
          ) {
            isValid = true;
          }
          const metadata = newSource?.source_details?.sourceMetadata;
          console.log(metadata, s3);
          if (
            metadata?.tableOrder &&
            metadata?.hasControlFile &&
            newSource?.source_details?.sourceMetadata?.cloud?.s3?.schemaType
          ) {
            isValid = true;
          }
        }
      }
      if (isValid) {
        setSourceDetailsRequired("");
        setValue(nextValue);
      } else {
        setSourceDetailsRequired(errorMessage);
      }
    }
  };

  const addRow = () => {
    const newField = { name: "", type: "text", nullable: true };
    setNewSource((prevState) => ({
      ...prevState,
      source_schema: {
        ...prevState.source_schema,
        schema: {
          ...prevState.source_schema.schema,
          fields: [...prevState.source_schema.schema.fields, newField],
        },
      },
    }));
  };

  const handleFieldChange = (event, index, fieldName) => {
    const { value } = event.target;
    const booleanValue =
      value === "true" ? true : value === "false" ? false : null;
    let updatedSource = { ...newSource };
    let fieldToUpdate = updatedSource.source_schema.schema.fields[index];
    fieldToUpdate[fieldName] = booleanValue;
    setNewSource(updatedSource);
    setSchemaRequired("");
  };

  const removeRow = (indexToRemove) => {
    setNewSource((prevState) => ({
      ...prevState,
      source_schema: {
        ...prevState.source_schema,
        schema: {
          ...prevState.source_schema.schema,
          fields: prevState.source_schema.schema.fields.filter(
            (_, index) => index !== indexToRemove
          ),
        },
      },
    }));
  };
  const handleInputChange = (event, path) => {
    console.log(newSource);
    const { name, value } = event.target;
    if (path == "sourceMetadata") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata[name] = value;
    } else if (path == "s3Path") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.path[name] = value;
    } else if (path == "azurePath") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.path[name] =
        value;
    } else if (path == "dataFile") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.dataFile[name] =
        value;
    } else if (path == "controlFile") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.controlFile[name] =
        value;
    } else if (path == "connection") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3.connectionDetails[
        name
      ] = value;
    } else if (path == "dataFileAzure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.dataFile[name] =
        value;
    } else if (path == "controlFileAzure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.controlFile[
        name
      ] = value;
    } else if (path == "connectionAzure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure.connectionDetails[
        name
      ] = value;
    } else if (path == "s3") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.s3[name] = value;
    } else if (path == "azure") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.cloud.azure[name] = value;
    } else if (path == "kafka") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.streaming.kafka[name] = value;
    } else if (path == "databaseInfo") {
      var updatedSource = { ...newSource };
      updatedSource.source_details.sourceMetadata.database.postgres.databaseInfo[
        name
      ] = value;
    } else {
      var updatedSource = { ...newSource };
      updatedSource[name] = value;
      if (value == "database") {
        updatedSource.source_details.sourceMetadata["source"] = "postgres";
      }
    }
    setSourceDetailsRequired("");
    setNewSource(updatedSource);
  };

  const getTemplateData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/metadata/template_source/allsources/`
      );
      let data = response?.data;
      console.log(data);
      setTemplateData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (isFirstRender.current && props?.ele) {
      setNewSource(props?.ele);
      setCronValue(props?.ele?.schedule);
      isFirstRender.current = false;
    }
    updateTimestamps();
  }, [props]);

  useEffect(() => {
    setCronValue(newSource?.schedule);
  }, [selectedTemplate]);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!isFirstRun.current) {
      setNewSource((prevSource) => {
        if (prevSource && newSource) {
          return {
            ...prevSource,
            schedule: cronValue,
          };
        }
        return { ...newSource, schedule: cronValue };
      });
    }
  }, [cronValue, newSource]);

  useEffect(() => {
    if (isFirstRender.current) {
      setNewSource(initColumn);
      isFirstRender.current = false;
    }
  }, [initColumn]);

  const updateTimestamps = async () => {
    setNewSource((source) => ({
      ...source,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
    }));
  };

  const handleSelectChange = (event) => {
    const selectedDataName = event.target.value;
    setSelectedTemplate(selectedDataName);
    const selectedTemplateData = templateData.find(
      (template) => template.data_name === selectedDataName
    );
    if (selectedTemplateData) {
      setNewSource(selectedTemplateData);
    }
  };

  const createNewSource = async () => {
    let isValid = false;
    let errorMessage = "Please fill all required fields";
    setNewSource((source) => ({
      ...source,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      schedule: cronValue,
    }));

    newSource?.source_schema?.schema?.fields.forEach((ele) => {
      if (ele?.name && ele?.type && ele?.nullable !== undefined) {
        isValid = true;
      }
    });

    if (isValid) {
      setSourceLoader(true);
      try {
        const response = await axios.post(
          `${apiUrl}/metadata/source/`,
          newSource
        );
        let data = response?.data;
        setSourceLoader(false);
        toast.success(data?.message);
        navigate("/ETL");
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setSourceLoader(false);
        toast.error(error?.response?.data?.error);
      }
    } else {
      setSchemaRequired(errorMessage);
    }

    return isValid;
  };

  const UpdateSource = async () => {
    try {
      const { edit, ...sourceWithoutEdit } = newSource;
      setNewSource((source) => ({
        ...source,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        schedule: cronValue,
      }));
      const response = await axios.put(
        `${apiUrl}/metadata/source/`,
        sourceWithoutEdit
      );
      let data = response?.data;
      toast.success(data?.message);
      navigate("/ETL");
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getTemplateData();
  }, []);

  const handleNavigate = () => {
    navigate("/ETL");
  };

  useEffect(() => {
    if (newSource?.edit === true) {
      if (newSource?.source_schema == null) {
        newSource.source_schema = {
          schema: {
            fields: [
              {
                name: "",
                type: "text",
                nullable: true,
              },
            ],
          },
        };
      } else if (newSource.source_schema.schema == null) {
        newSource.source_schema.schema = {
          fields: [
            {
              name: "",
              type: "text",
              nullable: true,
            },
          ],
        };
      } else if (newSource.source_schema.schema.fields == null) {
        newSource.source_schema.schema.fields = [
          {
            name: "",
            type: "text",
            nullable: true,
          },
        ];
      }
    }
  }, [newSource?.edit]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderColor: "divider" }}>
        {sourceLoader ? (
          <div
            className="position-relative"
            style={{ paddingTop: "15rem", paddingBottom: "2rem" }}
          >
            <div className="backdrop"></div>
            <div className="spinner-container">
              <Loader />
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="ms-4">Create Source</h5>
          <button
            className="me-4 form-control pt-1 pb-1 d-flex align-items-center"
            style={{
              width: "fit-content",
              background: "none",
              color: "#0A0839",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleNavigate}
          >
            <i className="fas fa-arrow-left me-2" />{" "}
          </button>
        </div>
        <Tabs
          value={value}
          className="ms-4"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Basic Details"
            {...a11yProps(0)}
            style={{ textTransform: "capitalize" }}
          />
          <Tab
            label="Source Details"
            {...a11yProps(1)}
            style={{ textTransform: "capitalize" }}
          />
          {/* <Tab label="Target Details" {...a11yProps(2)} /> */}
          <Tab
            label="Schema"
            {...a11yProps(2)}
            style={{ textTransform: "capitalize" }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Card className="p-4">
          <div>
            <div>
              <div className="row">
                {!props?.ele ? (
                  <div className="col-4">
                    <label>Select Template</label>
                    <select
                      className="form-control"
                      name="template"
                      onChange={handleSelectChange}
                      value={selectedTemplate}
                    >
                      <option value="">Select template</option>
                      {templateData.map((ele) => (
                        <option value={ele?.data_name}>{ele?.data_name}</option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label>Data Name</label>
                <span className="text-danger ms-1">*</span>
                <input
                  type="text"
                  className="form-control"
                  name="data_name"
                  disabled={newSource?.edit}
                  value={newSource?.data_name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
              <div className="col-4">
                <label>Entity Name</label>
                <span className="text-danger ms-1">*</span>
                <input
                  type="text"
                  className="form-control"
                  name="entity_name"
                  disabled={newSource?.edit}
                  value={newSource?.entity_name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
              <div className="col-4">
                <label>Source Type</label>
                <span className="text-danger ms-1">*</span>
                <select
                  className="form-control"
                  name="source_type"
                  value={newSource?.source_type}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                >
                  <option selected="" value="">
                    Select source type
                  </option>
                  <option value="cloud">File</option>
                  <option value="streaming">Streaming</option>
                  <option value="database">Database</option>
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <label>Schedule</label>
                <CronTab
                  cronTabValue={cronValue}
                  setCronTabValue={setCronValue}
                />
              </div>
            </div>
          </div>
          <div className="row d-flex mt-3">
            <div className="d-flex justify-content-end">
              {metaDataRequired != "" ? (
                <p className="p-0 text-danger me-4">{metaDataRequired}</p>
              ) : null}
              <div>
                <Button
                  style={{
                    color: "white",
                    background: "#0A0839",
                    border: "none",
                    paddingTop: "0.25rem !important",
                    paddingBottom: "0.25rem !important;",
                  }}
                  className="pt-1 pb-1 me-2"
                  onClick={handleNextTab}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Card className="p-4">
          {newSource?.source_type == "database" ? (
            <>
              <div className="col-2 mt-1">
                <p className="fw-bold">
                  Database Services<span className="text-danger ms-1">*</span>:{" "}
                </p>
              </div>
              <div className="col-3">
                <select
                  className="form-control"
                  name="source"
                  value={
                    newSource?.source_details?.sourceMetadata?.source ===
                    "postgres"
                      ? "OracleDb"
                      : newSource?.source_details?.sourceMetadata?.source
                  }
                  onChange={(e) => {
                    handleInputChange(e, "sourceMetadata");
                  }}
                >
                  <option value="oracleDb">OracleDB</option>
                  <option value="mongoDb" disabled>
                    MongoDB
                  </option>
                  <option value="postgres" disabled>
                    postgres
                  </option>
                </select>
              </div>
              <div className="mt-1">
                <p className="fw-bold pb-0 mt-3">Database Info </p>
              </div>
              <div className="row">
                <div className="col-4">
                  <label>Database Name</label>
                  <span className="text-danger ms-1">*</span>
                  <input
                    type="text"
                    className="form-control"
                    name="databaseName"
                    value={
                      newSource?.source_details?.sourceMetadata?.database
                        ?.postgres?.databaseInfo?.databaseName
                    }
                    onChange={(e) => {
                      handleInputChange(e, "databaseInfo");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Schema Name</label>
                  <span className="text-danger ms-1">*</span>
                  <input
                    type="text"
                    className="form-control"
                    name="schemaName"
                    value={
                      newSource?.source_details?.sourceMetadata?.database
                        ?.postgres?.databaseInfo?.schemaName
                    }
                    onChange={(e) => {
                      handleInputChange(e, "databaseInfo");
                    }}
                  />
                </div>
                {/* <div className="col-4">
                            <label>Replication Factor</label>
                            <input
                              type="text"
                              className="form-control"
                              name="replicationFactor"
                              value={
                                newSource?.source_details?.sourceMetadata?.streaming
                                  ?.kafka?.replicationFactor
                              }
                              onChange={(e) => {
                                handleInputChange(e, "kafka");
                              }}
                            />
                          </div> */}
              </div>
            </>
          ) : newSource?.source_type == "streaming" ? (
            <>
              <div className="row mt-1">
                <div className="col-4">
                  <label>Bootstrap Servers</label>
                  <span className="text-danger ms-1">*</span>
                  <input
                    type="text"
                    className="form-control"
                    name="bootstrapServers"
                    value={
                      newSource?.source_details?.sourceMetadata?.streaming
                        ?.kafka?.bootstrapServers
                    }
                    onChange={(e) => {
                      handleInputChange(e, "kafka");
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>Source Topic</label>
                  <span className="text-danger ms-1">*</span>
                  <input
                    type="text"
                    className="form-control"
                    name="sourceTopic"
                    value={
                      newSource?.source_details?.sourceMetadata?.streaming
                        ?.kafka?.sourceTopic
                    }
                    onChange={(e) => {
                      handleInputChange(e, "kafka");
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="d-flex row">
                  <div className="col-2 mt-1">
                    <p className="fw-bold">
                      Cloud Services<span className="text-danger ms-1">*</span>{" "}
                      :{" "}
                    </p>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control"
                      name="source"
                      value={newSource?.source_details?.sourceMetadata?.source}
                      onChange={(e) => {
                        handleInputChange(e, "sourceMetadata");
                      }}
                    >
                      <option selected="" value="">
                        Select service
                      </option>
                      <option value="s3">S3</option>
                      <option value="azure">Azure</option>
                    </select>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="fw-bold">Path Details</p>
                </div>
                <div className="row">
                  {newSource?.source_details?.sourceMetadata?.source ==
                  "azure" ? (
                    <>
                      <div className="col-4">
                        <label>Blob Container</label>
                        <span className="text-danger ms-1">*</span>
                        <input
                          type="text"
                          className="form-control"
                          name="blobContainer"
                          value={
                            newSource?.source_details?.sourceMetadata?.cloud
                              ?.azure?.path?.blobContainer
                          }
                          onChange={(e) => {
                            handleInputChange(e, "azurePath");
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <label>Folder</label>
                        <span className="text-danger ms-1">*</span>
                        <input
                          type="text"
                          className="form-control"
                          name="blobPath"
                          value={
                            newSource?.source_details?.sourceMetadata?.cloud
                              ?.azure?.path?.blobPath
                          }
                          onChange={(e) => {
                            handleInputChange(e, "azurePath");
                          }}
                        />
                      </div>
                      <div className="mt-2">
                        <p className="fw-bold">Data Files Details</p>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4">
                          <label>Header</label>
                          <span className="text-danger ms-1">*</span>
                          <select
                            className="form-control"
                            name="header"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.header
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          >
                            <option selected="" value="">
                              Select Header
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>File Type</label>
                          <span className="text-danger ms-1">*</span>
                          <select
                            className="form-control"
                            name="fileType"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.fileType
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          >
                            <option selected="" value="">
                              Select File type
                            </option>
                            <option value="csv">CSV</option>
                            <option value="txt">TXT</option>
                            <option value="zip">ZIP</option>
                            <option value="gz">GZ</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>Delimiter</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="delimiter"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.delimiter
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4">
                          <label>Files to Exclude</label>
                          <input
                            type="text"
                            className="form-control"
                            name="excludeFileNameAffix"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.excludeFileNameAffix
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Table Order</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="tableOrder"
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.tableOrder
                            }
                            onChange={(e) => {
                              handleInputChange(e, "sourceMetadata");
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Schema Type</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="schemaType"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.schemaType
                            }
                            onChange={(e) => {
                              handleInputChange(e, "azure");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4">
                          <label>Filename Pattern</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="fileNameAffix"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.azure?.dataFile?.fileNameAffix
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFileAzure");
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Has Control File</label>
                          <span className="text-danger ms-1">*</span>
                          <select
                            className="form-control"
                            name="hasControlFile"
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.hasControlFile
                            }
                            onChange={(e) => {
                              handleInputChange(e, "sourceMetadata");
                            }}
                          >
                            <option selected="" value="">
                              Select Control File Details
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      {newSource?.source_details?.sourceMetadata
                        ?.hasControlFile == "true" && (
                        <>
                          <div className="mt-2">
                            <p className="fw-bold">Control Files Details</p>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4">
                              <label>File Name Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                name="fileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.fileNameAffix
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>Affix Type</label>
                              <select
                                className="form-control"
                                name="affixType"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.affixType
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              >
                                <option selected="" value="">
                                  Select Affix type
                                </option>
                                <option value="prefix">Prefix</option>
                                <option value="suffix">Suffix</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <label>Archived File Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                name="archivedFileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile
                                    ?.archivedFileNameAffix
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              />
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4">
                              <label>Header</label>
                              <select
                                className="form-control"
                                name="header"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.header
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              >
                                <option selected="" value="">
                                  Select Header
                                </option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <label>Delimiter</label>
                              <input
                                type="text"
                                className="form-control"
                                name="delimiter"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.delimiter
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>File Type</label>
                              <select
                                className="form-control"
                                name="fileType"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile?.fileType
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              >
                                <option selected="" value="">
                                  Select File type
                                </option>
                                <option value="csv">CSV</option>
                                <option value="txt">TXT</option>
                                <option value="zip">ZIP</option>
                                <option value="gz">GZ</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4">
                              <label>Files to Exclude</label>
                              <input
                                type="text"
                                className="form-control"
                                name="excludeFileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.controlFile
                                    ?.excludeFileNameAffix
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFileAzure");
                                }}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <p className="fw-bold">Connection Details</p>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <label>S3 URI</label>
                              <input
                                type="text"
                                className="form-control"
                                name="s3URI"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.connectionDetails?.s3URI
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "connectionAzure");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>Auth Type</label>
                              <input
                                type="text"
                                className="form-control"
                                name="authType"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.connectionDetails?.authType
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "connectionAzure");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>Region</label>
                              <input
                                type="text"
                                className="form-control"
                                name="region"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.azure?.connectionDetails?.region
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "connectionAzure");
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-4">
                        <label>S3 Bucket</label>
                        <span className="text-danger ms-1">*</span>
                        <input
                          type="text"
                          className="form-control"
                          name="s3Bucket"
                          value={
                            newSource?.source_details?.sourceMetadata?.cloud?.s3
                              ?.path?.s3Bucket
                          }
                          onChange={(e) => {
                            handleInputChange(e, "s3Path");
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <label>Folder</label>
                        <span className="text-danger ms-1">*</span>
                        <input
                          type="text"
                          className="form-control"
                          name="folder"
                          value={
                            newSource?.source_details?.sourceMetadata?.cloud?.s3
                              ?.path?.folder
                          }
                          onChange={(e) => {
                            handleInputChange(e, "s3Path");
                          }}
                        />
                      </div>
                      <div className="mt-2">
                        <p className="fw-bold">Data Files Details</p>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4">
                          <label>Header</label>
                          <span className="text-danger ms-1">*</span>
                          <select
                            className="form-control"
                            name="header"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.header
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFile");
                            }}
                          >
                            <option selected="" value="">
                              Select Header
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>File Type</label>
                          <span className="text-danger ms-1">*</span>
                          <select
                            className="form-control"
                            name="fileType"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.fileType
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFile");
                            }}
                          >
                            <option selected="" value="">
                              Select File type
                            </option>
                            <option value="csv">CSV</option>
                            <option value="txt">TXT</option>
                            <option value="zip">ZIP</option>
                            <option value="gz">GZ</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>Delimiter</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="delimiter"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.delimiter
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFile");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4">
                          <label>Files to Exclude</label>
                          <input
                            type="text"
                            className="form-control"
                            name="excludeFileNameAffix"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.excludeFileNameAffix
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFile");
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Table Order</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="tableOrder"
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.tableOrder
                            }
                            onChange={(e) => {
                              handleInputChange(e, "sourceMetadata");
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Schema Type</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="schemaType"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.schemaType
                            }
                            onChange={(e) => {
                              handleInputChange(e, "s3");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4">
                          <label>Filename Pattern</label>
                          <span className="text-danger ms-1">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="fileNameAffix"
                            value={
                              newSource?.source_details?.sourceMetadata?.cloud
                                ?.s3?.dataFile?.fileNameAffix
                            }
                            onChange={(e) => {
                              handleInputChange(e, "dataFile");
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <label>Has Control File</label>
                          <span className="text-danger ms-1">*</span>
                          <select
                            className="form-control"
                            name="hasControlFile"
                            value={
                              newSource?.source_details?.sourceMetadata
                                ?.hasControlFile
                            }
                            onChange={(e) => {
                              handleInputChange(e, "sourceMetadata");
                            }}
                          >
                            <option selected="" value="">
                              Select Control File Details
                            </option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      {newSource?.source_details?.sourceMetadata
                        ?.hasControlFile == "true" && (
                        <>
                          <div className="mt-2">
                            <p className="fw-bold">Control Files Details</p>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4">
                              <label>File Name Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                name="fileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.fileNameAffix
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>Affix Type</label>
                              <select
                                className="form-control"
                                name="affixType"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.affixType
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              >
                                <option selected="" value="">
                                  Select Affix type
                                </option>
                                <option value="prefix">Prefix</option>
                                <option value="suffix">Suffix</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <label>Archived File Affix</label>
                              <input
                                type="text"
                                className="form-control"
                                name="archivedFileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile
                                    ?.archivedFileNameAffix
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              />
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4">
                              <label>Header</label>
                              <select
                                className="form-control"
                                name="header"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.header
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              >
                                <option selected="" value="">
                                  Select Header
                                </option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <label>Delimiter</label>
                              <input
                                type="text"
                                className="form-control"
                                name="delimiter"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.delimiter
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>File Type</label>
                              <select
                                className="form-control"
                                name="fileType"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile?.fileType
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              >
                                <option selected="" value="">
                                  Select File type
                                </option>
                                <option value="csv">CSV</option>
                                <option value="txt">TXT</option>
                                <option value="zip">ZIP</option>
                                <option value="gz">GZ</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4">
                              <label>Files to Exclude</label>
                              <input
                                type="text"
                                className="form-control"
                                name="excludeFileNameAffix"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.controlFile
                                    ?.excludeFileNameAffix
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "controlFile");
                                }}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <p className="fw-bold">Connection Details</p>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <label>S3 URI</label>
                              <input
                                type="text"
                                className="form-control"
                                name="s3URI"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.connectionDetails?.s3URI
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "connection");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>Auth Type</label>
                              <input
                                type="text"
                                className="form-control"
                                name="authType"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.connectionDetails?.authType
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "connection");
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <label>Region</label>
                              <input
                                type="text"
                                className="form-control"
                                name="region"
                                value={
                                  newSource?.source_details?.sourceMetadata
                                    ?.cloud?.s3?.connectionDetails?.region
                                }
                                onChange={(e) => {
                                  handleInputChange(e, "connection");
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="row d-flex mt-2">
            <div className="d-flex justify-content-end">
              {sourceDetailsRequired != "" ? (
                <p className="p-0 text-danger me-4">{sourceDetailsRequired}</p>
              ) : null}
              <div>
                <Button
                  style={{
                    color: "white",
                    background: "#0A0839",
                    border: "none",
                  }}
                  className="pt-1 pb-1 me-2"
                  onClick={handleNextTab}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        <Card className="p-4">
          <div className="row d-flex">
            <div className="d-flex justify-content-end">
              <Button
                style={{
                  color: "white",
                  background: "#0A0839",
                  border: "none",
                }}
                className="pt-1 pb-1 me-2"
                onClick={handleNextTab}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={2}>
        <Card className="p-4">
          <div className="mt-2">
            <p className="fw-bold">Source Schema</p>
          </div>
          {newSource?.source_schema?.schema?.fields.map((ele, index) => (
            <div className="row mt-1" key={index}>
              <div className="col-4">
                {index === 0 && (
                  <label className="text-center w-100 fw-bold mb-2 pe-2">
                    Column Name<span className="text-danger ms-1">*</span>
                  </label>
                )}
                <input
                  type="text"
                  className="form-control"
                  name={`source_schema.schema.fields[${index}].name`}
                  value={ele?.name || ""}
                  onChange={(e) => handleFieldChange(e, index, "name")}
                />
              </div>
              <div className="col-4">
                {index === 0 && (
                  <label className="text-center w-100 fw-bold mb-2 pe-2">
                    Data Type<span className="text-danger ms-1">*</span>
                  </label>
                )}
                <select
                  className="form-control"
                  name={`source_schema.schema.fields[${index}].type`}
                  value={ele?.type || ""}
                  onChange={(e) => handleFieldChange(e, index, "type")}
                >
                  <option value="">Select Data type</option>
                  <option value="integer">INTEGER</option>
                  <option value="float">FLOAT</option>
                  <option value="long">LONG</option>
                  <option value="text">TEXT</option>
                  <option value="boolean">BOOLEAN</option>
                  <option value="string">STRING</option>
                  <option value="timestamp">TIMESTAMP</option>
                </select>
              </div>
              <div className="col-3">
                {index === 0 && (
                  <label className="text-center w-100 fw-bold mb-2 pe-2">
                    Nullable<span className="text-danger ms-1">*</span>
                  </label>
                )}
                <select
                  className="form-control"
                  name={`nullable_${index}`}
                  value={
                    ele?.nullable === true
                      ? "true"
                      : ele?.nullable === false
                      ? "false"
                      : ""
                  }
                  onChange={(e) => handleFieldChange(e, index, "nullable")}
                >
                  <option value="">Select Nullable</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="col-1 d-flex align-items-end">
                {index === newSource.source_schema.schema.fields.length - 1 ? (
                  <>
                    {index !== 0 && (
                      <Tooltip title="Remove" placement="top">
                        <button
                          type="button"
                          className="btn text-danger"
                          onClick={() => removeRow(index)}
                        >
                          <b>-</b>
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip title="Add New" placement="top">
                      <button
                        type="button"
                        className="btn text-primary"
                        onClick={addRow}
                      >
                        <b>+</b>
                      </button>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Remove" placement="top">
                    <button
                      type="button"
                      className="btn text-danger"
                      onClick={() => removeRow(index)}
                    >
                      <b>-</b>
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          ))}

          <div className="row d-flex mt-3">
            <div className="d-flex justify-content-end">
              {schemaRequired != "" ? (
                <p className="p-0 text-danger me-4">{schemaRequired}</p>
              ) : null}
              {newSource?.edit ? (
                <>
                  <div>
                    <Button
                      style={{
                        color: "white",
                        background: "#0A0839",
                        border: "none",
                      }}
                      className="pt-1 pb-1"
                      onClick={() => {
                        UpdateSource();
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Button
                      style={{
                        color: "white",
                        background: "#0A0839",
                        border: "none",
                      }}
                      className="pt-1 pb-1"
                      onClick={() => {
                        createNewSource();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </CustomTabPanel>
    </Box>
  );
}
