import { Outlet } from "react-router-dom";
import React, { Suspense } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import "../views/ui/main.css";
import Loader from "../layouts/loader/Loader";

const FullLayout = () => {
  return (
    <main>
      <Header />
      <div className="pageWrapper d-lg-flex">
        <aside className="sidebarArea shadow" id="sidebarArea">
          <Sidebar />
        </aside>
        <div className="contentArea">
          <Container fluid className="mt-5 pt-4 overflow-auto">
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </Container>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
