import React from "react";
import BasicTabs from "./CreateTabination";
import { useLocation } from "react-router-dom";

function CreateETL() {
  const location = useLocation();
  const ele = location.state?.ele;
  return (
    <>
      <div className="p-0">
        <BasicTabs ele={ele}/>
      </div>
    </>
  );
}

export default CreateETL;
