import { Card, CardBody} from "reactstrap";

const Blog = (props) => {
  const handleClick = (loc) => {
    if (typeof loc === "string" && loc.trim() !== "") {
      if (loc.includes("repoai")) {
          window.open(loc, "RepoAI");
      } else {
        window.open(loc, "AceDo");
      }
    } else {
      console.error("Invalid location provided:", loc);
    }
  };

  return (
    <Card onClick={() => handleClick(props.loc)} style={{cursor:"pointer"}}>
      <CardBody className="p-4" style={{ height: "24rem" }}>
        <div className="text-center" style={{ height: "170px" }}>
          <img src={props.logo} />
        </div>
        <p className="d-flex justify-content-center fw-bold" tag="h5">
          {props.title}
        </p>
        <p
          style={{ fontSize: "13px", color: "grey" }}
          className="mt-3 text-center"
        >
          {props.text}
        </p>
      </CardBody>
    </Card>
  );
};

export default Blog;
