import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "../views/ui/main.css";
import "@fortawesome/fontawesome-free/css/all.css";
import bg1 from "../assets/images/bg/AceETL.png";
import bg2 from "../assets/images/bg/AceDo.png";
import bg5 from "../assets/images/bg/devtools.png";
import bg6 from "../assets/images/bg/swagger.png";
import bg7 from "../assets/images/bg/studio.png";
import bg8 from "../assets/images/bg/image2.png";
import image from "../assets/images/bg/image__12_-removebg-preview.png";
import Logo from "./Logo";
import logos from "../assets/images/bg/image__18_-removebg-preview.png";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { DataContext } from "../DataContext";
import Tooltip from "@mui/material/Tooltip";
const apiUrl = process.env.REACT_APP_API_URL;
const path = process.env.REACT_APP_ACE_AI;
const swagger = process.env.REACT_APP_SWAGGER;
const dse = process.env.REACT_APP_DSE_STUDIO;
const dse2 = process.env.REACT_APP_DSE_STUDIO2;

const Sidebar = () => {
  const location = useLocation();
  const { selectedChatId, setSelectedChatId } = useContext(DataContext);
  const { containerAdd, setContainerAdd } = useContext(DataContext);
  const { container, setContainer } = useContext(DataContext);
  const { newChat } = useContext(DataContext);
  const userRole = JSON.parse(sessionStorage.getItem("user"));
  const [exploreOpen, setExploreOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editContainer, setEditContainer] = useState(false);
  const [editName, setEditName] = useState("");
  const [editingChatId, setEditingChatId] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const toggleExplore = () => setExploreOpen(!exploreOpen);
  const userData = JSON.parse(sessionStorage.getItem("user"));

  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    width: "40%",
  };

  const adminNavigation = [
    { title: "AceETL", href: "/ETL", icon: bg1 },
    { title: "AceDO", href: "/ADO", icon: bg2 },
    { title: "AceAI", href: "/RepoAI", icon: image },
    {
      title: "CoPilot Config",
      href: "/Config",
      icon: bg8,
    },
  ];

  const devNavigation = [
    {
      title: "CoPilot",
      href: "/co-pilot",
      icon: logos,
      onClick: () => {
        setSelectedChatId(null);
        handleFunction();
      },
    },
    {
      title: "Dev Tools",
      href: "#",
      icon: bg5,
      onClick: toggleExplore,
      children: [
        {
          title: "AceAI",
          href: `${path}/home`,
          icon: image,
          isExternal: true,
        },
        {
          title: "AceAPI Docs",
          href: `${swagger}/ace-api/`,
          icon: bg6,
          isExternal: true,
        },
        {
          title: "AceAI Studio",
          href: `${dse}`,
          icon: bg7,
          isExternal: true,
        },
        // {
        //   title: "AceAI Studio-2",
        //   href: `${dse2}`,
        //   icon: bg7,
        //   isExternal: true,
        // },
      ],
    },
  ];

  const userNavigation = [
    {
      title: "CoPilot",
      href: "/co-pilot",
      icon: logos,
      onClick: () => {
        setSelectedChatId(null);
        handleFunction();
      },
    },
  ];

  let navigation = [];
  if (userRole?.role === "admin") {
    navigation = adminNavigation;
  } else if (userRole?.role === "dev") {
    navigation = devNavigation;
  } else {
    navigation = userNavigation;
  }

  const isActive = (href) =>
    href === "/ETL"
      ? location.pathname === href || location.pathname === "/createETL"
      : location.pathname === href;

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const toggleDrop = (e, chatId) => {
    const { clientX, clientY } = e;
    if (selectedChatId === chatId) {
      setDropdownVisible(true);
      setDropdownOpenId(chatId);
    } else {
      setDropdownVisible(true);
      setDropdownOpenId(chatId);
    }
    setDropdownPosition({
      top: clientY,
      left: clientX,
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
      setDropdownOpenId(null);
    }
  };

  const handleChatClick = (chatId) => {
    sessionStorage.setItem("containerId", chatId);
    setSelectedChatId(chatId);
    toggleDrop(chatId);
    setContainer(0);
  };
  const DropdownMenu = ({ position, onEdit, onDelete }) => {
    return (
      <div
        role="menu"
        aria-orientation="vertical"
        className=" mt-1 rounded popover hover:bg-[#fff]"
        style={{
          zIndex: "100000",
          position: "fixed",
          left: position.left + 10,
          top: position.top,
        }}
        ref={dropdownRef}
      >
        <MenuItem icon="fa-solid fa-pen" title="Edit" onClick={onEdit} />
        <MenuItem icon="fa-solid fa-trash" title="Delete" onClick={onDelete} />
      </div>
    );
  };

  const MenuItem = ({ icon, title, onClick }) => (
    <div
      role="menuitem"
      className="flex text-black d-flex items-center  p-2.5 text-sm cursor-pointer focus-visible:outline-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group hover:bg-[#f5f5f5] focus-visible:bg-[#f5f5f5] dark:hover:bg-token-main-surface-secondary dark:focus-visible:bg-token-main-surface-secondary rounded-md my-0 px-3 radix-state-open:bg-[#f5f5f5] dark:radix-state-open:bg-token-main-surface-secondary gap-2.5 py-1"
      tabIndex="-1"
      onClick={onClick}
    >
      <div className="flex items-center justify-center text-token-text-secondary h-5 w-5 me-3">
        <i className={`fas ${icon}`}></i>
      </div>
      {title}
    </div>
  );

  const getChatHistory = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/copilot/get-chat-containers/`,
        { params: { user_id: userData?.user_id } }
      );
      let data = response?.data;
      setChatHistory(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteChat = (ele) => {
    setDropdownVisible(false);
    setDeleteModal(true);
  };

  const handleFunction = () => {
    setContainerAdd((prevValue) => prevValue + 1);
  };

  const handleModalClosed = () => {
    setDropdownVisible(false);
    setDeleteModal(false);
  };

  const handleEditClick = (chatId, chatName) => {
    setEditingChatId(chatId);
    setEditName(chatName);
  };

  const handleSaveEdit = async () => {
    setDropdownVisible(false);
    try {
      let data = {
        chat_container_id: editingChatId,
        user_id: userData?.user_id,
        chat_container_name: editName,
      };

      await axios.post(`${apiUrl}/copilot/save-chat-container/`, data);

      setEditingChatId(null);
      getChatHistory();
    } catch (error) {
      console.error("Error communicating with the API:", error.message);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      let data = {
        chat_container_id: selectedChatId,
        user_id: userData?.user_id,
      };
      console.log(data);
      const response = await axios.delete(
        `${apiUrl}/copilot/delete-chat-container/`,
        {
          params: {
            chat_container_id: selectedChatId,
            user_id: userData?.user_id,
          },
        }
      );
      setDeleteModal(false);
      getChatHistory();
      setContainerAdd((prevValue) => prevValue + 1);
      setSelectedChatId(null);
    } catch (error) {
      setDeleteModal(false);
      console.error("Error communicating with the API:", error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveEdit();
    }
  };

  const callContainer = async () => {
    if (newChat) {
      try {
        const response = await axios.get(
          `${apiUrl}/copilot/get-chat-containers/`,
          { params: { user_id: userData?.user_id } }
        );
        let data = response?.data;
        console.log(data);
        setSelectedChatId(data?.todayChatHistory?.[0]?.chat_container_id);
        setChatHistory(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (userData?.role == "dev" || userData?.role == "endUser") {
      getChatHistory();
    }
    setSelectedChatId(null);
  }, []);

  useEffect(() => {
    callContainer();
  }, [newChat]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="position-fixed mt-2">
      <div className="d-flex align-items-center navbar-left ms-auto pt-1">
        <div className="d-lg-block d-none">
          <Logo />
        </div>
        <div>
          <h4 className="fw-bold text-white">BleuBird</h4>
        </div>
      </div>
      <div className="mt-3">
        <Nav vertical className="sidebarNav">
          {navigation.map((item, index) => (
            <NavItem
              key={index}
              className={
                exploreOpen ? "sidenav-bg bar mb-1" : "sidenav-bg mb-1"
              }
            >
              {item.title === "CoPilot" ? (
                <Tooltip title="New Chat" placement="right">
                  <Link
                    to={item.href}
                    onClick={item.onClick ? item.onClick : null}
                    style={{ padding: "10px 16px 10px 24px", width: "240px" }}
                    className={
                      isActive(item.href)
                        ? "active nav-link d-flex align-items-center"
                        : "nav-link text-white d-flex align-items-center"
                    }
                  >
                    {typeof item.icon === "string" &&
                    item.icon.includes("image") ? (
                      <img
                        src={item.icon}
                        alt={item.title}
                        style={{ width: "28px", height: "24px" }}
                      />
                    ) : (
                      item.icon
                    )}
                    <span
                      className={
                        isActive(item.href)
                          ? "ms-3 d-inline-block fw-bold"
                          : "ms-3 d-inline-block"
                      }
                    >
                      {item.title}
                    </span>
                    {item.children && (
                      <>
                        {exploreOpen ? (
                          <i className="ms-auto fa-solid fa-caret-up"></i>
                        ) : (
                          <i className="ms-auto fa-solid fa-caret-down"></i>
                        )}
                      </>
                    )}
                  </Link>
                </Tooltip>
              ) : (
                <Link
                  to={item.href}
                  onClick={item.onClick ? item.onClick : null}
                  style={{ padding: "10px 16px 10px 24px", width: "240px" }}
                  className={
                    isActive(item.href)
                      ? "active nav-link d-flex align-items-center"
                      : "nav-link text-white d-flex align-items-center"
                  }
                >
                  {typeof item.icon === "string" &&
                  item.icon.includes("image") ? (
                    <img
                      src={item.icon}
                      alt={item.title}
                      style={{ width: "28px", height: "24px" }}
                    />
                  ) : (
                    item.icon
                  )}
                  <span
                    className={
                      isActive(item.href)
                        ? "ms-3 d-inline-block fw-bold"
                        : "ms-3 d-inline-block"
                    }
                  >
                    {item.title}
                  </span>
                  {item.children && (
                    <>
                      {exploreOpen ? (
                        <i className="ms-auto fa-solid fa-caret-up"></i>
                      ) : (
                        <i className="ms-auto fa-solid fa-caret-down"></i>
                      )}
                    </>
                  )}
                </Link>
              )}

              {item.children && (
                <Collapse isOpen={exploreOpen}>
                  {item.children.map((child, childIndex) => (
                    <NavItem key={childIndex} className="ms-2 me-2 sidenav">
                      {child.isExternal ? (
                        <a
                          href={child.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ padding: "10px 16px" }}
                          className={
                            isActive(child.href)
                              ? "active nav-link d-flex align-items-center"
                              : "nav-link text-white d-flex align-items-center"
                          }
                        >
                          {typeof child.icon === "string" &&
                          child.icon.includes("image") ? (
                            <img
                              src={child.icon}
                              alt={child.title}
                              style={{ width: "24px", height: "20px" }}
                            />
                          ) : (
                            child.icon
                          )}
                          <span
                            className={
                              isActive(child.href)
                                ? "ms-3 d-inline-block fw-bold"
                                : "ms-3 d-inline-block"
                            }
                          >
                            {child.title}
                          </span>
                        </a>
                      ) : (
                        <Link
                          to={child.href}
                          style={{ padding: "10px 16px" }}
                          className={
                            isActive(child.href)
                              ? "active nav-link d-flex align-items-center"
                              : "nav-link text-white d-flex align-items-center"
                          }
                        >
                          {typeof child.icon === "string" &&
                          child.icon.includes("image") ? (
                            <img
                              src={child.icon}
                              alt={child.title}
                              style={{ width: "24px", height: "20px" }}
                            />
                          ) : (
                            child.icon
                          )}
                          <span
                            className={
                              isActive(child.href)
                                ? "ms-3 d-inline-block fw-bold"
                                : "ms-3 d-inline-block"
                            }
                          >
                            {child.title}
                          </span>
                        </Link>
                      )}
                    </NavItem>
                  ))}
                </Collapse>
              )}
            </NavItem>
          ))}
          {(userRole?.role === "dev" || userRole?.role === "endUser") && (
            <>
              <div>
                <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
                  <div className="z-20">
                    <span className="flex h-9 items-center">
                      <h6 className="fw-bold pb-2 pt-3 px-2">Today</h6>
                    </span>
                  </div>
                  <div
                    className="relative first:mt-0 last:mb-5 empty:mt-0 empty:hidden"
                    style={{
                      opacity: 1,
                      width: "230px",
                    }}
                  >
                    {chatHistory?.todayChatHistory?.map((chat) => (
                      <div
                        key={chat.id}
                        className={`chatContainer ${
                          selectedChatId === chat.chat_container_id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleChatClick(chat.chat_container_id)}
                      >
                        <div className="px-2 p-1 rounded group relative rounded-lg active:opacity-90 d-flex">
                          {editingChatId === chat.chat_container_id ? (
                            <div className="d-flex">
                              <div style={{ width: "160px" }}>
                                <input
                                  type="text"
                                  className="form-control w-100 bg-white editInput"
                                  name="chat_container_name"
                                  value={editName}
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) => setEditName(e.target.value)}
                                />
                              </div>
                              <i
                                className="ms-3 fa-solid fa-floppy-disk fs-5 mt-2"
                                onClick={handleSaveEdit}
                              ></i>
                            </div>
                          ) : (
                            <>
                              <div
                                className="overflow-hidden mb-2 ps-2 truncatedText"
                                dir="auto"
                              >
                                <Tooltip
                                  title={chat?.chat_container_name}
                                  placement="top"
                                >
                                  {truncateText(chat.chat_container_name, 21)}
                                </Tooltip>
                              </div>
                              <i
                                className="ms-auto mt-1 fa-solid fa-ellipsis-h cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleDrop(e, chat?.chat_container_id);
                                }}
                              ></i>
                              {dropdownVisible &&
                                dropdownOpenId === chat?.chat_container_id && (
                                  <DropdownMenu
                                    onEdit={() =>
                                      handleEditClick(
                                        chat.chat_container_id,
                                        chat.chat_container_name
                                      )
                                    }
                                    onDelete={() =>
                                      handleDeleteChat(chat.chat_container_id)
                                    }
                                    position={dropdownPosition}
                                    ref={dropdownRef}
                                  />
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="z-20">
                    <span className="flex h-9 items-center">
                      <h6 className="fw-bold pb-2 pt-3 px-2">Previous Days</h6>
                    </span>
                  </div>
                  <div
                    className="relative mt-1 first:mt-0 last:mb-5 empty:mt-0 empty:hidden"
                    style={{
                      opacity: 1,
                      width: "230px",
                    }}
                  >
                    {chatHistory?.previousDaysChatHistory?.map((chat) => (
                      <div
                        key={chat.id}
                        className={`chatContainer ${
                          selectedChatId === chat.chat_container_id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleChatClick(chat.chat_container_id)}
                      >
                        <div className="px-2 p-1 rounded group relative rounded-lg active:opacity-90 d-flex">
                          {editingChatId === chat.chat_container_id ? (
                            <div className="d-flex">
                              <div style={{ width: "160px" }}>
                                <input
                                  type="text"
                                  className="form-control w-100 bg-white editInput"
                                  name="chat_container_name"
                                  value={editName}
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) => setEditName(e.target.value)}
                                />
                              </div>
                              <i
                                className="ms-3 fa-solid fa-floppy-disk fs-5 mt-2"
                                onClick={handleSaveEdit}
                              ></i>
                            </div>
                          ) : (
                            <>
                              <div
                                className="overflow-hidden mb-2 ps-2 truncatedText"
                                dir="auto"
                              >
                                <Tooltip
                                  title={chat?.chat_container_name}
                                  placement="top"
                                >
                                  {truncateText(chat.chat_container_name, 21)}
                                </Tooltip>
                              </div>
                              <i
                                className="ms-auto p-2 fa-solid fa-ellipsis-h cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleDrop(e, chat?.chat_container_id);
                                }}
                              ></i>
                              {dropdownVisible &&
                                dropdownOpenId === chat?.chat_container_id && (
                                  <DropdownMenu
                                    onEdit={() =>
                                      handleEditClick(
                                        chat.chat_container_id,
                                        chat.chat_container_name
                                      )
                                    }
                                    onDelete={() =>
                                      handleDeleteChat(chat.chat_container_id)
                                    }
                                    position={dropdownPosition}
                                    ref={dropdownRef}
                                  />
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </Nav>
      </div>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "30%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Delete Chat container</h5>
          </div>
          <div className=" d-flex justify-content-center align-items-center p-3">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className=" d-flex justify-content-center align-items-center pb-3">
            <button
              className=" ms-5 me-4 form-control "
              onClick={() => handleModalClosed()}
            >
              No
            </button>
            <button
              className="form-control me-5 "
              onClick={() => handleConfirmDelete()}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Sidebar;
