import React, { useState, startTransition } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Card } from "@mui/material";
import Logo from "../../assets/images/logos/logo.png";
import "./main.css";
const AuthWrapper1 = styled("div")(({ theme }) => ({
  background: "whitesmoke",
  minHeight: "100vh",
}));

const DUMMY_USERS = [
  {
    email: "admin@bleubird.com",
    password: "admin123",
    role: "admin",
    name: "Christopher",
    user_id: "bb-dev-user-1",
  },
  {
    email: "dev@bleubird.com",
    password: "dev123",
    role: "dev",
    name: "Alicia",
    user_id: "bb-dev-user-1",
  },
  {
    email: "enduser@bleubird.com",
    password: "enduser123",
    role: "endUser",
    name: "Steven",
    user_id: "bb-dev-user-1",
  },
];

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);

    const user = DUMMY_USERS.find(
      (user) => user.email === values.email && user.password === values.password
    );

    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      startTransition(() => {
        if (user.role === "admin") {
          navigate("/ETL");
        } else {
          navigate("/co-pilot");
        }
      });
    } else {
      setErrors({ submit: "Invalid email or password" });
    }

    setSubmitting(false);
  };

  return (
    <AuthWrapper1>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <Card
                sx={{
                  maxWidth: { xs: 400, lg: 475 },
                  p: { xs: 2, sm: 3, xl: 5 },
                  borderRadius: "40px",
                  margin: { xs: 2.5, md: 3 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "50%",
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={{ xs: "column-reverse", md: "row" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <div className="d-flex me-3">
                            <img
                              src={Logo}
                              alt="Logo"
                              style={{ width: 60, height: 40 }}
                            />
                            <Typography
                              style={{ color: "#0A0839" }}
                              gutterBottom
                              sx={{ fontSize: "24px", fontWeight: 500 }}
                              variant={matchDownSM ? "h3" : "h5"}
                              className="pt-1"
                            >
                              BleuBird
                            </Typography>
                          </div>
                          <Typography
                            style={{ color: "#0A0839" }}
                            gutterBottom
                            sx={{ fontSize: "26px", fontWeight: 500 }}
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Hi, Welcome Back
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="ms-3">
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      spacing={2}
                      className="mb-4 mt-5"
                    >
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                          submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email("Must be a valid email")
                            .max(255)
                            .required("Email is required"),
                          password: Yup.string()
                            .max(255)
                            .required("Password is required"),
                        })}
                        onSubmit={handleSubmit}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                        }) => (
                          <form noValidate onSubmit={handleSubmit}>
                            <FormControl
                              fullWidth
                              error={Boolean(touched.email && errors.email)}
                            >
                              <TextField
                                id="outlined-adornment-email-login"
                                type="email"
                                label="Email Address / Username"
                                value={values.email}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(touched.email && errors.email)}
                              />
                            </FormControl>

                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              sx={{ mt: "10px" }}
                            >
                              <TextField
                                id="outlined-adornment-password-login"
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                value={values.password}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                // InputProps={{
                                //   endAdornment: (
                                //     <InputAdornment position="end">
                                //       <IconButton
                                //         aria-label="toggle password visibility"
                                //         onClick={handleClickShowPassword}
                                //         onMouseDown={handleMouseDownPassword}
                                //         edge="end"
                                //         size="large"
                                //       >
                                //         {showPassword ? (
                                //           <Visibility />
                                //         ) : (
                                //           <VisibilityOff />
                                //         )}
                                //       </IconButton>
                                //     </InputAdornment>
                                //   ),
                                // }}
                                helperText={touched.password && errors.password}
                                error={Boolean(
                                  touched.password && errors.password
                                )}
                              />
                            </FormControl>

                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              spacing={1}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked}
                                    onChange={(event) =>
                                      setChecked(event.target.checked)
                                    }
                                    name="checked"
                                    color="primary"
                                  />
                                }
                                label="Remember me"
                              />
                              <Typography
                                variant="subtitle1"
                                color="#0A0839"
                                sx={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                              >
                                Forgot Password?
                              </Typography>
                            </Stack>
                            {errors.submit && (
                              <Box sx={{ mt: 3 }}>
                                <FormHelperText error>
                                  {errors.submit}
                                </FormHelperText>
                              </Box>
                            )}

                            <Box sx={{ mt: 2 }}>
                              <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{ color: "white", background: "#0A0839" }}
                              >
                                Sign in
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      xs={12}
                    >
                      <Typography
                        component={Link}
                        to="register"
                        color="#0A0839"
                        variant="subtitle1"
                        sx={{ textDecoration: "none" }}
                      >
                        Don&apos;t have an account?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}></Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Login;
