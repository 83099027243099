import React from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute.jsx";

// Import components directly
import FullLayout from "../layouts/FullLayout";
import Tables from "../views/ui/Tables";
import Cards from "../views/ui/AceDO";
import Badges from "../views/ui/RepoAI";
import CreateETL from "../views/ui/CreateETL";
import CoPilot from "../views/ui/CoPilot";
import Login from "../views/ui/Login";
import Config from "../views/ui/Config.jsx";
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/ETL" /> },
      {
        path: "/ETL",
        exact: true,
        element: <ProtectedRoute roles={["admin"]} element={<Tables />} />,
      },
      {
        path: "/ADO",
        exact: true,
        element: <ProtectedRoute roles={["admin"]} element={<Cards />} />,
      },
      {
        path: "/RepoAI",
        exact: true,
        element: <ProtectedRoute roles={["dev", "admin"]} element={<Badges />} />,
      },
      {
        path: "/Config",
        exact: true,
        element: <ProtectedRoute roles={["admin"]} element={<Config />} />,
      },
      {
        path: "/createETL",
        exact: true,
        element: <ProtectedRoute roles={["admin"]} element={<CreateETL />} />,
      },
      {
        path: "/co-pilot",
        exact: true,
        element: (
          <ProtectedRoute roles={["dev", "endUser"]} element={<CoPilot />} />
        ),
      },
    ],
  },
  { path: "/login", exact: true, element: <Login /> },
];

export default ThemeRoutes;