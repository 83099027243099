import React, { useState, startTransition, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Collapse, Nav } from "reactstrap";
import Logo from "./Logo";
import { DataContext } from "../DataContext.js";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedChatId, setSelectedChatId } = useContext(DataContext);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();

  const handleLogout = () => {
    startTransition(() => {
      sessionStorage.clear();
      setSelectedChatId(null);
      navigate("/login");
    });
  };
  console.log(selectedChatId,"test");

  return (
    <Navbar
      color="white"
      dark
      expand="md"
      className="fix-header fixed-top"
      style={{ borderBottom: "2px solid whitesmoke" }}
    >
      <div className="d-flex align-items-center navbar-left">
        <div className="d-lg-block d-none">
          <Logo />
        </div>
        <div>
          <h4 className="fw-bold text-white">BleuBird</h4>
        </div>
      </div>

      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto" navbar>
          {/* <NavItem>
            <Link to="/starter" className="nav-link">
              Starter
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about" className="nav-link">
              About
            </Link>
          </NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              DD Menu
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </Nav>
        <p className="my-auto fs-6 fw-bold me-3">Hello, {user?.name}</p>
        <i
          onClick={() => {
            handleLogout();
          }}
          class=" fa-solid fa-power-off"
          style={{ cursor: "pointer" }}
        ></i>
        {/* <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent">
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Info</DropdownItem>
            <DropdownItem>My Account</DropdownItem>
            <DropdownItem>Edit Profile</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>My Balance</DropdownItem>
            <DropdownItem>Inbox</DropdownItem>
            <DropdownItem>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
      </Collapse>
    </Navbar>
  );
};

export default Header;
