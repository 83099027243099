import { Row, Col, Button } from "reactstrap";
import Blog from "../../components/dashboard/Blog";
import bg1 from "../../assets/images/bg/image (4).png";
import bg2 from "../../assets/images/bg/image (5).png";
const path = process.env.REACT_APP_ACE_AI;

const BlogData = [
  {
    title: "​Document Repo Creation",
    description:
      "​Create document repositories by adding documents and train them to embed with LLM for efficient organization and retrieval.",
    location: `${path}/repoView`,
    logo: bg1,
  },
  {
    title: "Generative AI's",
    description:
      "Train custom LLMs on datasets to maximize business value and enhance AI capabilities.",
    location: `${path}/chatView`,
    logo: bg2,
  },
];

const handleButtonClick = () => {
  const windowName = "RepoAI";
  const url = `${path}/home`;
  const existingWindow = window.open(url, windowName);

  if (existingWindow) {    
    existingWindow.focus();
  }
};

const RepoAI = () => {
  return (
    <div className="p-4 pt-2">
      <div className="d-flex justify-content-center">
        <h5 className="mb-3 me-5 fw-bold align-content-center">
          RepoAI - Generative AI
        </h5>
      </div>
      <div className="d-flex justify-content-center text-secondary">
        <p>
          Enable business and IT to train custom LLMs on datasets, unlocking
          generative AI's full potential.
        </p>
      </div>
      <Row className="mt-5 d-flex justify-content-center me-5">
        {BlogData.map((blg, index) => (
          <Col sm="6" lg="6" xl="3" key={index}>
            <Blog
              title={blg.title}
              text={blg.description}
              loc={blg.location}
              logo={blg.logo}
            />
          </Col>
        ))}
      </Row>
      <div className="row d-flex">
        <div className="d-flex justify-content-center me-5">
          <Button
            style={{
              color: "white",
              background: "#0A0839",
              border: "none",
            }}
            className="me-5"
            onClick={handleButtonClick}
          >
            View all features
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RepoAI;
