import React, { useEffect, useRef, useState } from "react";
import { Card, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import "./dataTable.css";
import { Box, Tabs, Tab } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import "@fortawesome/fontawesome-free/css/all.css";
import bg1 from "../../assets/images/bg/valid.png";
import bg2 from "../../assets/images/bg/rule.png";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../layouts/loader/Loader";
const apiUrl = process.env.REACT_APP_API_URL;
const path = process.env.REACT_APP_ACE_DO;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const currentDate = new Date().toISOString();
const initColumn = {
  data_name: "",
  entity_name: "",
  source_type: "",
  source_details: {
    sourceMetadata: {
      tableOrder: 0,
      groupId: "",
      hasControlFile: false,
      source: "s3",
      cloud: {
        s3: {
          path: {
            s3Bucket: "",
            folder: "",
          },
          schemaType: "",
          dataFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
            filename: "",
          },
          controlFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
          },
          connectionDetails: {
            s3URI: "",
            authType: "",
            region: "",
            secretName: "",
          },
          fileTypeForMissingFileValidation: "",
        },
        azure: {
          path: {
            blobContainer: "",
            blobPath: "",
          },
          schemaType: "",
          dataFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
          },
          controlFile: {
            affixType: "",
            fileNameAffix: "",
            archivedFileNameAffix: "",
            fileType: "",
            delimiter: "",
            header: false,
            excludeFileNameAffix: [],
          },
          connectionDetails: {
            storageAccount: "",
            blobURI: "",
            authType: "",
            secretName: "",
          },
          fileTypeForMissingFileValidation: "",
        },
      },
      streaming: {
        kafka: {
          bootstrapServers: "",
          partitions: null,
          replicationFactor: null,
          sourceTopic: "",
        },
      },
      database: {
        mongoDb: {},
        postgres: {
          TableDetails: {
            tableName: "",
            excludeTableName: [],
          },
          controlTableDetails: {
            tableName: "",
            excludeTableAffix: [],
          },
          databaseInfo: {
            databaseName: "",
            schemaName: "",
          },
        },
        oracleDb: {},
      },
    },
  },
  source_schema: {
    schema: {
      type: "struct",
      fields: [{ name: "", type: "text", nullable: "true" }],
    },
    constraints: {
      primarykeys: [],
    },
    extractDate: {
      isAvailable: false,
      columnName: null,
    },
  },
  target_type: "cassandra",
  target_details: {
    targetSystems: {
      snowflake: {
        snowFlakeTableName: "",
        tableType: "",
      },
      cassandra: {
        cassandraTableName: "",
        keyspace: "",
        tableType: "",
      },
    },
  },
  target_schema: {
    schema: {
      type: "struct",
      fields: [],
    },
    constraints: {
      primarykeys: [],
    },
    extractDate: {
      isAvailable: false,
      columnName: null,
    },
  },
  frequency: "",
  schedule: "0 5 * * 3",
  created_at: currentDate,
  modified_at: currentDate,
};

const Tables = () => {
  const tableRef = useRef(null);
  const datasetDataTableRef = useRef(null);
  const datasetTableRef = useRef(null);
  const navigate = useNavigate();
  const [selectedEle, setSelectedEle] = useState(null);
  const [etlData, setEtlData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState([]);
  const [clickedRow, setClickedRow] = useState(0);
  const [value, setValue] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [copySource, setCopySource] = useState(false);
  const [newSource, setNewSource] = useState(initColumn);
  const [newDuplicateError, setNewDuplicateError] = useState("");
  const [sourceToDelete, setSourceToDelete] = useState("");
  const [jobStatus, setJobStatus] = useState([]);
  const [loader, setLoader] = useState(true);
  const [Etlloader, setEtlLoader] = useState(false);
  const [stop, setStop] = useState(false);
  const [stopData, setStopData] = useState([]);
  const [sourceDuplicate, setSourceDuplicate] = useState(false);
  console.log(currentStatus, "currentStatus");

  const modelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    width: "40%",
  };

  const changeClicked = (ele, index) => {
    setClickedRow(index);
  };

  const handleCloneClick = (ele) => {
    setSelectedEle(ele);
    navigate("/createETL", { state: { ele } });
  };

  const handleClick = async (ele, process) => {
    if (ele?.source_type == "streaming") {
      if (process == "start") {
        toast.success(
          "Initiating ETL job for " + ele?.data_name + " - " + ele?.entity_name
        );
      }
      try {
        setStop(false);
        let data = {
          data_name: ele?.data_name,
          entity_name: ele?.entity_name,
          source_type: ele?.source_type,
          action: process,
        };
        if (process == "stop") {
          toast.success(
            `Stopping the streaming job for ${data?.data_name} and ${data?.entity_name}. This may take a few moments...`
          );
        }
        const response = await axios.post(
          `${apiUrl}/metadata/streaming/`,
          data
        );
        if (process == "start") {
          toast.success("ETL job flow has been triggered successfully!");
        } else {
          toast.success("ETL job stopped successfully!");
        }
        getJobStatus();
        setTimeout(() => {
          getCurrentStatus();
        }, 5000);
      } catch (error) {
        console.error("Error running deployment:", error);
        toast.error(error?.response?.data?.error);
        setSourceDuplicate(false);
        setStop(false);
      }
    } else {
      toast.success(
        "Initiating ETL job for " + ele?.data_name + " - " + ele?.entity_name
      );
      try {
        let data = {
          data_name: ele?.data_name,
          entity_name: ele?.entity_name,
          source_type: ele?.source_type,
        };
        const response = await axios.post(
          `${apiUrl}/metadata/prefect_flow/start/`,
          data
        );
        toast.success("ETL job flow has been triggered successfully!");
        getJobStatus();
      } catch (error) {
        console.error("Error running deployment:", error);
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const handleDuplicate = (ele) => {
    let data = JSON.parse(JSON.stringify(ele));
    data.entity_name = "";
    setNewSource(data);
    setCopySource(true);
    setNewDuplicateError("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditSource = (ele) => {
    const updatedEle = { ...ele, edit: true };
    setSelectedEle(updatedEle);
    navigate("/createETL", { state: { ele: updatedEle } });
  };

  const handleDeleteSource = (ele) => {
    setSourceToDelete(ele);
    setDeleteModal(true);
  };

  const handleStop = (ele) => {
    setStopData(ele);
    setStop(true);
  };

  const handleModalClosed = () => {
    setDeleteModal(false);
  };

  const handleModalClosedETL = () => {
    setStop(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        // `${apiUrl}/metadata/source/${sourceToDelete?.data_name}/${sourceToDelete?.entity_name}/`
        `${apiUrl}/metadata/source/${sourceToDelete?.data_name}/${sourceToDelete?.entity_name}/${sourceToDelete?.source_type}/`
      );
      setDeleteModal(false);
      getSourceData();
      toast.success("Source deleted successfully");
    } catch (error) {
      setDeleteModal(false);
      console.error("Error communicating with the API:", error.message);
      toast.error(error?.response?.data?.error);
    }
  };

  const handleCreateSource = async () => {
    if (newSource && newSource?.entity_name) {
      setSourceDuplicate(true);
      setNewSource((source) => ({
        ...source,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
      }));
      try {
        const response = await axios.post(
          `${apiUrl}/metadata/source/`,
          newSource
        );
        let data = response?.data;
        toast.success(data?.message);
        getSourceData();
        setCopySource(false);
        setSourceDuplicate(false);
      } catch (error) {
        setSourceDuplicate(false);
        console.error("Failed to fetch data:", error);
        toast.error(error?.response?.data?.error);
      }
    } else {
      setNewDuplicateError("Please fill the Entity name");
    }
  };

  const getTemplateData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/metadata/template_source/allsources/`
      );
      let data = response?.data;
      console.log(data);
      setTemplateData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getCurrentStatus = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}//metadata/streaming/jobs/status/`
      );
      let data = response?.data;
      console.log(data);
      setCurrentStatus(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getSourceData = async () => {
    try {
      setEtlLoader(true);
      const response = await axios.get(`${apiUrl}/metadata/source/`);
      let data = response?.data;
      console.log(data);
      setEtlData(data);
      setEtlLoader(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getJobStatus = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/metadata/prefect_flow/jobs/history/`
      );
      let data = response?.data;
      console.log(data);
      setJobStatus(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (value == "1") {
      getJobStatus();
      const intervalId = setInterval(() => {
        getJobStatus();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [value]);
  useEffect(() => {
    if (value == "0") {
      getCurrentStatus();
      const intervalId = setInterval(() => {
        getCurrentStatus();
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, []);

  const clickRow = async (ele, type) => {
    try {
      let data = {
        entity_name: ele?.entity_name,
        source_type: ele?.source_type == "cloud" ? "file" : ele?.source_type,
        entity_group: ele?.data_name?.toLowerCase(),
      };
      const jsonData = JSON.stringify(data);
      const encoder = new TextEncoder();
      const binaryData = encoder.encode(jsonData);
      const base64Data = btoa(String.fromCharCode(...binaryData));
      if (type == "source") {
        var baseUrl = path;
      } else {
        var baseUrl = `${path}/RawDataRules`;
      }
      const url = `${baseUrl}?data=${encodeURIComponent(base64Data)}`;
      window.open(url, "AceDo");
    } catch (error) {
      console.error("Encoding failed", error);
    }
  };

  useEffect(() => {
    getSourceData();
    // getTemplateData();
    getJobStatus();
  }, []);

  const headers = jobStatus?.length > 0 ? Object.keys(jobStatus[0]) : [];

  const columns = [
    {
      name: "GROUP",
      selector: (row) => row.data_name,
      style: { fontSize: "16px" },
      sortable: true,
      width: "17% !important",
    },
    {
      name: "ENTITY NAME",
      selector: (row) => row.entity_name,
      style: { fontSize: "16px" },
      sortable: true,
      width: "19% !important",
    },
    {
      name: "TYPE",
      selector: (row) =>
        row.source_type === "cloud" ? "File" : row.source_type,
      style: {
        fontSize: "16px",
        textTransform: "capitalize",
      },
      sortable: true,
      width: "13% !important",
    },
    {
      name: "SOURCE",
      selector: (row) =>
        row.source_details?.sourceMetadata?.source === "postgres"
          ? "Oracle"
          : row.source_details?.sourceMetadata?.source,
      style: {
        fontSize: "16px",
        textTransform: "capitalize",
      },
      sortable: true,
      width: "13% !important",
    },
    {
      name: "TARGET",
      selector: (row) => row.target_type,
      style: {
        fontSize: "16px",
        textTransform: "capitalize",
      },
      sortable: true,
      width: "13% !important",
    },
    {
      name: "AceDO",
      width: "10% !important",
      cell: (row) => (
        <div
          className="text-center"
          style={{
            display: "flex",
            gap: "3px",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Source Validation" placement="top">
            <img
              src={bg1}
              alt="Source Validation"
              onClick={(event) => {
                event.stopPropagation();
                clickRow(row, "source");
              }}
              style={{ cursor: "pointer", height: "28px", width: "28px" }}
            />
          </Tooltip>
          <Tooltip title="Rules Validation" placement="top">
            <img
              src={bg2}
              alt="Rules Validation"
              onClick={(event) => {
                event.stopPropagation();
                clickRow(row, "rules");
              }}
              style={{ cursor: "pointer", height: "32px", width: "32px" }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      name: "ACTION",
      width: "10% !important",
      cell: (row) => (
        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
          <Tooltip title="Edit" placement="top">
            <i
              className="fa-solid fa-pen text-secondary"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                handleEditSource(row);
              }}
            ></i>
          </Tooltip>
          <Tooltip title="Duplicate" placement="top">
            <i
              className="fa-solid fa-copy text-secondary"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                handleDuplicate(row);
              }}
            ></i>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <i
              className="fa-solid fa-trash"
              style={{ cursor: "pointer", color: "#976565" }}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteSource(row);
              }}
            ></i>
          </Tooltip>

          {row.source_type !== "streaming" && (
            <Tooltip title="Start" placement="top">
              <i
                className="fa-solid fa-play text-success"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(row, "start");
                }}
              ></i>
            </Tooltip>
          )}
          {row.source_type === "streaming" && (
            <>
              {currentStatus.some(
                (status) =>
                  status.key_name === `${row.data_name}_${row.entity_name}`
              ) ? (
                <Tooltip title="Stop" placement="top">
                  <i
                    className="fa-solid fa-stop"
                    style={{ cursor: "pointer", color: "#F76161" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleStop(row);
                    }}
                  ></i>
                </Tooltip>
              ) : (
                <Tooltip title="Start" placement="top">
                  <i
                    className="fa-solid fa-play text-success"
                    style={{ cursor: "pointer" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(row, "start");
                    }}
                  ></i>
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const columnJob = [
    {
      name: "JOB ID",
      selector: (row) => row.job_id,
      style: { fontSize: "16px", width: "40%" },
      sortable: true,
    },
    {
      name: "JOB NAME",
      selector: (row) => row.job_name,
      style: { fontSize: "16px", width: "25%" },
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      style: { fontSize: "16px", width: "20%" },
      sortable: true,
    },
    {
      name: "TIME",
      selector: (row) => row.when,
      style: { fontSize: "16px", width: "15%" },
      sortable: true,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Tabs
          value={value}
          className="ms-4"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {/* <Tab
            className="pe-2 me-0 fs-6"
            label="Template"
            style={{ textTransform: "capitalize" }}
          /> */}
          <Tab
            className="ps-2 ms-0 fs-6"
            label="Source"
            style={{ textTransform: "capitalize" }}
          />
          <Tab
            className="ps-2 ms-0 fs-6"
            label="Job Status"
            style={{ textTransform: "capitalize" }}
          />
        </Tabs>
        {value == "0" && (
          <Button
            style={{
              color: "white",
              background: "#0A0839",
              border: "none",
              height: "36px",
            }}
            className="mt-2 me-4"
            onClick={() => {
              navigate("/createETL");
            }}
          >
            Create Source
          </Button>
        )}
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
        <Card className="p-4">
          <div className="table-responsive">
            <table
              id="data-table-basic"
              className="table w-100 table-striped table-hover"
              ref={tableRef}
              style={{ color: "#ffffff" }}
              border={1}
            >
              <thead
                style={{
                  backgroundColor: "#0A0839",
                }}
              >
                <tr>
                  <th style={{ fontSize: "14px" }}>TEMPLATE NAME</th>
                  <th style={{ fontSize: "14px" }}>SOURCE TYPE</th>
                  <th style={{ fontSize: "14px" }}>SOURCE DETAILS</th>
                  <th style={{ fontSize: "14px" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {templateData?.map((ele, index) => (
                  <tr
                    key={index}
                    className={index === clickedRow ? "clicked-row" : ""}
                    onClick={() => changeClicked(ele, index)}
                  >
                    <td>{ele?.data_name}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {ele?.source_type == "cloud" ? "File" : ele?.source_type}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {ele?.source_details?.sourceMetadata?.source}
                    </td>
                    <td>
                      <Tooltip title="Clone" placement="top">
                        <i
                          className="fa-solid fa-clone mt-1 ms-3 text-secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleCloneClick(ele)}
                        ></i>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={0}>
        {sourceDuplicate ? (
          <div
            className="position-fixed d-flex justify-content-center align-items-center"
            style={{
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 9999,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="backdrop"></div>
            <div className="spinner-container">
              <Loader />
            </div>
          </div>
        ) : null}
        {Etlloader ? (
          <div style={{ paddingTop: "15rem", paddingBottom: "2rem" }}>
            <Loader />
          </div>
        ) : (
          <>
            {etlData?.length > 0 ? (
              <Card className="p-4 pb-0 mb-0">
                <>
                  {" "}
                  <DataTable
                    columns={columns}
                    data={etlData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 25, 50]}
                    // highlightOnHover
                    pointerOnHover
                    customStyles={{
                      table: {
                        style: {
                          backgroundColor: "#fff",
                          color: "#ffffff",
                          border: "1px solid #ECECEC",
                          zIndex: "100",
                          fontSize: "16px",
                          width: "100%",
                        },
                      },
                      rows: {
                        style: {
                          backgroundColor: "#fff",
                          "&:nth-of-type(odd)": {
                            backgroundColor: "#ECECEC",
                          },
                          minHeight: "39px !important",
                        },
                      },
                      headRow: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                      cells: {
                        style: {
                          borderColor: "#ffffff",
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </>
              </Card>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <p>No source available</p>
                </div>
              </>
            )}
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <>
          {loader ? (
            <div style={{ paddingTop: "15rem", paddingBottom: "2rem" }}>
              <Loader />
            </div>
          ) : (
            <Card className="p-4 pb-0 mb-0">
              {jobStatus.length ? (
                <DataTable
                  columns={columnJob}
                  data={jobStatus}
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[5, 10, 25, 50]}
                  // highlightOnHover
                  pointerOnHover
                  onRowClicked={(row, event) =>
                    changeClicked(row, event.currentTarget.dataset.index)
                  }
                  customStyles={{
                    table: {
                      style: {
                        backgroundColor: "#fff",
                        color: "#ffffff",
                        border: "1px solid #ECECEC",
                        zIndex: "100",
                        fontSize: "16px",
                        tableLayout: "fixed",
                      },
                    },
                    rows: {
                      style: {
                        backgroundColor: "#fff",
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#ECECEC",
                        },
                        minHeight: "39px !important",
                      },
                    },
                    headRow: {
                      style: {
                        fontSize: "16px",
                      },
                    },
                    cells: {
                      style: {
                        borderColor: "#ffffff",
                        fontSize: "16px",
                      },
                    },
                  }}
                />
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <p>No jobs available</p>
                  </div>
                </>
              )}
            </Card>
          )}
        </>
      </CustomTabPanel>
      <Modal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "30%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Delete Source</h5>
          </div>
          <div className=" d-flex justify-content-center align-items-center p-3">
            <p>Are you sure you want to delete?</p>
          </div>
          <div className=" d-flex justify-content-center align-items-center pb-3">
            <button
              className=" ms-5 me-4 form-control "
              onClick={() => handleModalClosed()}
            >
              No
            </button>
            <button
              className="form-control me-5 "
              onClick={() => handleConfirmDelete()}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={stop}
        onClose={() => setStop(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "30%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Stop ETL</h5>
          </div>
          <div className=" d-flex justify-content-center align-items-center p-3">
            <p>Are you sure you want to stop the ETL job?</p>
          </div>
          <div className=" d-flex justify-content-center align-items-center pb-3">
            <button
              className=" ms-5 me-4 form-control "
              onClick={() => handleModalClosedETL()}
            >
              No
            </button>
            <button
              className="form-control me-5 "
              onClick={() => handleClick(stopData, "stop")}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={copySource}
        onClose={() => setCopySource(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle} style={{ width: "40%" }}>
          <div
            className="d-flex justify-content-center align-items-center p-3"
            style={{
              backgroundColor: "#0A0839",
              color: "white",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              height: "40px",
            }}
          >
            <h5 className="text-uppercase">Duplicate Source</h5>
          </div>
          <div className="row mt-3 ms-2 me-2">
            <div className="col-6">
              <label>Data Name</label>
              <input
                type="text"
                className="form-control"
                name="data_name"
                disabled="true"
                value={newSource?.data_name}
              />
            </div>
            <div className="col-6">
              <label>Entity Name</label>
              <span className="text-danger ms-1">*</span>
              <input
                type="text"
                className="form-control"
                name="entity_name"
                value={newSource?.entity_name}
                onChange={(event) => {
                  const { name, value } = event.target;
                  setNewSource((prevSource) => ({
                    ...prevSource,
                    [name]: value,
                  }));
                  setNewDuplicateError("");
                }}
              />
            </div>
          </div>
          <div className="row mt-3 ms-2 me-2">
            <div className="col-6">
              <label>Source Type</label>
              <input
                type="text"
                className="form-control"
                name="data_name"
                disabled="true"
                value={newSource?.source_type}
              />
            </div>
            <div className="col-6">
              <label>Source Details</label>
              <input
                type="text"
                className="form-control"
                name="entity_name"
                disabled="true"
                value={
                  newSource?.source_details?.sourceMetadata?.source ===
                  "postgres"
                    ? "Oracle"
                    : newSource?.source_details?.sourceMetadata?.source
                }
              />
            </div>
          </div>
          <div className="row mt-3 ms-2 me-2">
            <div className="col-6">
              <label>Target Type</label>
              <input
                type="text"
                className="form-control"
                name="target_type"
                disabled="true"
                value={newSource?.target_type}
              />
            </div>
          </div>
          <div className=" d-flex justify-content-end align-items-center pb-3 mt-3">
            <div>
              {newDuplicateError ? (
                <>
                  <p className="text-danger me-3 mt-2 pt-2">
                    {newDuplicateError}
                  </p>
                </>
              ) : null}
            </div>
            <button
              className="form-control me-5 ps-4 pe-4"
              style={{
                backgroundColor: "#0A0839",
                color: "white",
                width: "fit-content",
              }}
              onClick={() => handleCreateSource()}
            >
              Duplicate
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Tables;
